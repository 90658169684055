<template><div>
   <p><b>Copy CASE package API link</b></p>
	<p>A framework’s CASE JSON is available via the <strong>CASE REST API.</strong>
	<ul>
		<li>Select the <strong>Kebab menu</strong> within the framework.</li>
		<li>Select EXPORT FRAMEWORK / COPY FRAMEWORK LINK</li>
		<li>Select COPY CASE PACKAGE <strong>API</strong> LINK</li>
		<li>Choose the preferred format to copy it to your clipboard
			<ul>
				<li>The <strong>CASE 1.0</strong> version of the framework’s JSON, will include only fields that are part of the official CASE 1.0 specification. </li>
				<li>Alternatively, the <strong>CASE 1.1</strong> version includes additional item fields that are part of the official CASE 1.1 specification.</li>
				<li>Choosing the <strong>Extended CASE</strong> version includes additional fields that are included in <span v-html="app_name"/> but are not part of the official CASE specification (e.g. Supplemental Information fields for items)</li>
			</ul>
		</li>
		<li>Click SELECT FORMAT and the link will be copied to the clipboard</li>
	</ul>
</p>
       
       </div></template>
       <script>
           import HelpMixin from './HelpMixin'
           export default {
               mixins: [HelpMixin],
           }
       </script>
       
       <style lang="scss">
       </style>