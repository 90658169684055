<template><div>
   <p><b>Export to spreadsheet</b></p>
	<ul>
		<li class="k-no-clear">A framework, or a selection of a framework and its associations can be exported to a CSV (spreadsheet) format from the <strong>items table</strong> or <strong>associations table</strong></li>
		<li>Select the SWITCH TO TABLE VIEW icon. <img srcset="/docimages/items_table.png 1x" class="k-help-img"></li> 
		<li>Use the SHOW FILTERS button located in the top right corner to select or deselect the columns you want to appear in your CSV download. <img srcset="/docimages/show_filter_export_new.png 1.5x" class="k-help-img block"></li>
		<li>Choose ITEMS or ASSOCIATIONS sub tabs located in the top right corner</li>
		<li>Verify that the <span v-html="app_name"/> <span v-html="link('batch_add_from_text', 'table view')"></span> contains the expected data.</li>
		<li>Choose the column headers to sort the data to confirm the values present.</li>
		<li>Select the EXPORT button to process the CSV export. An EXPORT ITEM DATA pop-up will appear confirming the number of items which will be exported.</li>
		<li>Click PROCEED to begin the export.</li>
	</ul>
    
    </div></template>
    <script>
        import HelpMixin from './HelpMixin'
        export default {
            mixins: [HelpMixin],
        }
    </script>
    
    <style lang="scss">
    </style>