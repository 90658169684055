<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div style="width:100vw; height:calc(100vh - 60px); padding:0 12px 12px 12px; background-color:#eee;">
<div v-if="!resources_loaded" class="text-center" style="padding-top:120px; font-size:24px;"><b>LOADING RESOURCES...</b></div>
<div v-if="resources_loaded">
	<div class="d-flex align-center pl-2 pt-5 pb-5">
		<h3 class="k-custom-heading-color d-flex align-center">
			<v-icon color="primary" style="margin:-4px 8px 0 0; font-size:32px; cursor:pointer" @click="show_table">fas fa-cubes-stacked</v-icon>
			<div style="margin:-2px 0 0 4px;" v-html="resource_set.resource_set_title"></div>
			<v-btn icon small style="margin:-3px 0 0 10px" @click="U.show_help('framework_index')" color="light-blue"><v-icon>fas fa-info-circle</v-icon></v-btn>
			<v-btn v-if="resource_alignment_validation" x-small class="ml-2" color="secondary" @click="resource_alignment_validation=false">Alignment Validation On</v-btn>
		</h3>
		<v-spacer/>

		<div v-if="!viewing_resource" class="mr-1" style="max-width:320px;"><v-text-field v-model="search_text" dense outlined rounded clearable prepend-inner-icon="fa fa-search" label="Search resources" background-color="#fff" single-line hide-details @input="handle_search"></v-text-field></div>

		<v-menu :transition="false" bottom left><template v-slot:activator="{on}"><v-btn v-on="on" color="#333" icon class="ml-3 mr-1" v-show="can_create_new&&!viewing_resource"><v-icon>fas fa-ellipsis-vertical</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item @click="rename_set"><v-list-item-icon><v-icon small>fas fa-font</v-icon></v-list-item-icon><v-list-item-title>Rename resource set</v-list-item-title></v-list-item>
				<v-list-item @click="add_set_editor"><v-list-item-icon><v-icon small>fas fa-user</v-icon></v-list-item-icon><v-list-item-title>Add an editor for the resource set</v-list-item-title></v-list-item>
				<v-list-item @click="create_new_resource"><v-list-item-icon><v-icon small>fas fa-circle-plus</v-icon></v-list-item-icon><v-list-item-title>Add new resource</v-list-item-title></v-list-item>
				<v-list-item @click="start_resource_import"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Import resources, metadata, and/or alignments</v-list-item-title></v-list-item>
				<v-list-item @click="start_resource_export"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export alignments</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</div>

	<div class="k-framework-list-wrapper k-resource-set-wrapper elevation-3" :class="wrapper_css" :style="wrapper_style"><div class="k-framework-list-wrapper-inner">
		<!-- Framework chooser / alignment settings -->
		<div v-if="!viewing_resource" class="d-flex align-start mt-3 white k-resource-set-alignment-settings" style="font-size:16px; line-height:20px;">
			<v-spacer v-if="!tba_framework_identifier"/>
			<v-btn v-if="tba_framework_identifier" icon small color="primary" class="ml-2 mt-1" @click="clear_tba_framework"><v-icon>fas fa-circle-xmark</v-icon></v-btn>
			<div>
				<FrameworkSwitcher2 :btn_size="tba_framework_identifier?'small':'large'" color="brown darken-4" :current_selected_framework_identifier="tba_framework_identifier" @framework_selected="set_tba_framework" />
			</div>
			<div v-if="tba_framework_identifier" class="ml-1 mt-2" style="font-size:18px">
				<div><b>Aligning to:</b> <span class="ml-1" v-html="tba_framework_record_descriptor"></span></div>

				<div v-if="tba_available_item_types" class="mt-4 d-flex align-center">
					<div class="mr-2">Item types to align to:</div>
					<v-select v-model="limiters.item_types" :items="tba_available_item_types" @change="tba_item_types_updated" label="" dense outlined hide-details multiple small-chips deletable-chips>
						<template v-slot:item="{item}"><div style="width:600px">
							<div class="d-flex align-center">
								<v-icon class="mr-2">{{ limiters.item_types.includes(item) ? 'fas fa-square-check' : 'far fa-square' }}</v-icon>
								<div style="font-size:16px;"">{{ item }}</div>
								<div style="font-size:14px;margin-left:4px">({{tba_item_type_counts[item]}})</div>
								<v-btn x-small text color="secondary" class="ml-2" @click.stop="get_item_type_example(item)">Sample<v-icon v-if="item_type_examples[item]" small class="ml-2">fas fa-recycle</v-icon></v-btn>
							</div>
							<div v-show="item_type_examples[item]" class="mt-1 mb-2" style="font-size:14px; line-height:18px;" v-html="item_type_examples[item]"></div>
						</div></template>
					</v-select>
				</div>

				<div class="mt-4 d-flex align-center">
					<div class="mr-2">“Base Framework” for cross-alignments:</div>
					<v-autocomplete :menu-props="'dense'" dense hide-details outlined label="" :items="base_framework_options" v-model="base_framework_identifier"></v-autocomplete>
				</div>

				<div class="d-flex mt-4">
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="show_aligned_resources" :label="`Show Aligned (${n_alignments_to_tba_framework})`"></v-checkbox>
					<v-checkbox class="shrink mt-0 pt-0" hide-details v-model="show_unaligned_resources" :label="`Show Unaligned (${resources.length - n_alignments_to_tba_framework})`"></v-checkbox>
					<v-btn v-if="!batch_align_settings_showing" class="ml-8" small color="pink darken-3" dark @click="batch_align_start_clicked"><v-icon small class="mr-2">fas fa-wand-magic-sparkles</v-icon>Batch Align…</v-btn>
				</div>

				<div v-show="batch_align_available&&batch_align_settings_showing" :class="tfr_compute_paused?'k-resource-set-tfr-paused':''" class="pink lighten-5 mt-2 px-3 pt-3 pb-3" style="border-radius:10px; font-size:15px; width:660px;">
					<div class="d-flex align-center">
						<v-spacer/>
						<v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="batch_max_alignments_is_set"></v-checkbox>
						<div class="text-right" style="flex:0 1 auto">Max auto-alignments per resource:</div>
						<b class="grey darken-4 white--text ml-2 mr-1" style="font-size:16px; width:40px; text-align:center; border-radius:5px;">{{(batch_max_alignments==-1)?'–':batch_max_alignments}}</b>
						<div style="width:220px; height:32px"><v-slider v-if="batch_max_alignments_is_set" v-model="batch_max_alignments" hide-details dense min="1" max="10" step="1" thumb-label></v-slider></div>
					</div>
					<div class="d-flex align-center">
						<div class="text-right" style="flex:1 0 auto">Auto-align to standards with simscores ≥</div>
						<b class="grey darken-4 white--text ml-2 mr-1" style="font-size:16px; width:40px; text-align:center; border-radius:5px;">{{batch_simscore_threshold}}</b>
						<div style="width:220px"><v-slider v-model="batch_simscore_threshold" hide-details dense min="0" max="1000" step="10" thumb-label></v-slider></div>
					</div>
					<!-- note that we need the v-show for batch_alignments_to_create to calculate n_batch_alignments_to_create -->
					<div class="d-flex align-center mt-2" v-show="batch_alignments_to_create.length>0">
						<div style="font-size:18px" class="mr-3"><nobr>These batch settings will result in <b class="grey darken-4 white--text mx-1" style="display:inline-block; padding:0 6px; text-align:center; border-radius:5px;">{{n_batch_alignments_to_create}}</b> auto-{{U.ps('alignment',n_batch_alignments_to_create)}}</nobr></div>
						<v-spacer/>
						<v-btn v-if="n_batch_alignments_to_create>0" color="pink darken-3" dark @click="do_batch_alignments">  <v-icon small class="mr-2">fas fa-wand-magic-sparkles</v-icon>Do It!  </v-btn>
						<v-btn icon color="secondary" class="ml-3" @click="batch_align_settings_showing=false"><v-icon large>fas fa-circle-xmark</v-icon></v-btn>
					</div>
				</div>
			</div>

			<v-spacer v-if="tba_framework_identifier"/>

			<div v-if="tba_framework_identifier" class="ml-8" style="font-size:14px; width:540px; background-color:#eee; padding:12px; border-radius:10px;">
				<div class="d-flex align-center">
					<b style="font-size:18px" class="mr-4">{{(suggestions_to_compute>0)?'Computing Suggestions…':'Suggestion factors:'}}</b>
					<v-spacer/>
					<div v-if="suggestions_to_compute>0" class="d-flex">
						<v-spacer/>
						<div style="width:180px; position:relative; margin-top:2px">
							<v-progress-linear v-model="suggestions_progress" color="green darken-1" height="24"></v-progress-linear>
							<div style="position:absolute; right:4px; top:3px; font-size:12px; font-weight:bold">{{table_filtered_resources.length - suggestions_to_compute}} / {{this.table_filtered_resources.length}}</div>
						</div>
						<v-btn small color="red darken-3" class="ml-2 k-tight-btn" dark @click="cancel_get_all_suggestions"><v-icon small class="mr-1">fas fa-xmark</v-icon>Stop</v-btn>
						<v-spacer/>
					</div>
					<v-btn v-if="suggestions_to_compute<=0" small color="primary" @click="get_all_resource_alignment_suggestions"><v-icon small class="mr-2">fas fa-robot</v-icon>{{(suggestions_to_compute==0)?'Re-Compute':'Compute Suggestions'}}</v-btn>
				</div>
				<div class="d-flex align-center mt-4">
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="comp_factors.item" @change="comp_factors_changed"><template v-slot:label>Resource<v-icon x-small color="#333" style="margin:0 2px">fas fa-arrows-left-right</v-icon>Item Text</template></v-checkbox>
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="comp_factors.parent" @change="comp_factors_changed"><template v-slot:label>Resource<v-icon x-small color="#333" style="margin:0 2px">fas fa-arrows-left-right</v-icon>Parent Text</template></v-checkbox>
				</div>
				<div v-if="comp_factors.item||comp_factors.parent" class="d-flex align-center mt-2 ml-8">
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="comp_factors.title" @change="comp_factors_changed" label="Title"></v-checkbox>
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="comp_factors.description" @change="comp_factors_changed" label="Description"></v-checkbox>
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="comp_factors.text" @change="comp_factors_changed" label="Keywords"></v-checkbox>
				</div>
				<div class="d-flex align-center mt-2">
					<v-checkbox class="shrink mt-0 pt-0 mr-8" hide-details v-model="comp_factors.education_level" @change="comp_factors_changed" label="Ed. Level"></v-checkbox>
					<div class="mr-2"><b>Cross-Alignments:</b></div>
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="comp_factors.sme_assocs" @change="comp_factors_changed" label="SME-Based"></v-checkbox>
					<v-checkbox class="shrink mt-0 pt-0 mr-4" hide-details v-model="comp_factors.ai_assocs" @change="comp_factors_changed" label="AI-Based"></v-checkbox>
				</div>
			</div>
			<v-spacer v-if="!tba_framework_identifier"/>
		</div>

		<v-data-table v-show="!viewing_resource" dense :class="tfr_compute_paused?'k-resource-set-tfr-paused':''" class="k-framework-list-table k-resource-set-table my-2 mt-4" ref="resources_table" xhide-default-footer
			:headers="table_headers"
			:items="table_filtered_resources"
			:options="table_options"
			:footer-props="footer_options"
			:must-sort="true"
			:sort-by.sync="table_sort_by"
			:sort-desc.sync="table_sort_desc"
			:page.sync="current_page"
			:item-class="item=>(item.resource==this.last_viewed_resource?'k-resource-set-last-viewed-resource':'')"
			@pagination="pagination_changed"
		>
			<template v-slot:header.n_tba_alignments="{ item }"><span style="display:inline-block; margin-top:-4px">TBA<br>Alignments</span></template>
			<template v-slot:header.n_cross_alignments="{ item }"><span style="display:inline-block; margin-top:-4px">Cross-<br>Alignments</span></template>
			
			<template v-if="show_resource_identifiers" v-slot:item.external_resource_id="{ item }"><nobr style="font-size:12px">{{item.external_resource_id}}</nobr></template>
			<template v-slot:item.n_alignments="{ item }"><v-btn fab x-small style="width:28px; height:28px; margin:2px 0" :outlined="!item.n_alignments" class="elevation-0" :color="item.icon_color" dark @click="go_to_resource(item.resource_id,$event)"><span style="font-size:16px">{{item.n_alignments?item.n_alignments:'–'}}</span></v-btn></template>
			<template v-slot:item.n_tba_alignments="{ item }"><div class="d-flex align-center">
				<v-spacer/>
				<v-btn fab x-small style="width:28px; height:28px; margin:2px 0; letter-spacing:-0.5px" :outlined="!item.n_tba_alignments" class="elevation-0" :color="item.icon_color" dark @click="go_to_resource(item.resource_id,$event)"><span style="font-size:16px;margin-left:-2px;">{{item.n_tba_alignments?item.n_tba_alignments:'–'}}</span></v-btn>
				<span v-if="batch_align_settings_showing" v-visible="batch_alignments_hash[item.resource_id]" class="d-flex">
					<v-icon class="ml-2" color="pink darken-4" small>fas fa-wand-magic-sparkles</v-icon>
					<div style="width:30px" class="pink--text text--darken-4 text-left pl-1">{{batch_alignments_hash[item.resource_id] ? batch_alignments_hash[item.resource_id].alignments.length : 0}}</div>
					<v-spacer/>
				</span>
				<v-spacer/>
				
			</div></template>
			<template v-slot:item.n_cross_alignments="{ item }"><span :class="item.cross_alignment_cell_class"><b>{{item.n_cross_alignments?item.n_cross_alignments:'–'}}</b></span></template>

			<!-- <template v-slot:item.top_candidate_val="{ item }"><div class="k-resource-factor-tooltip-outer" v-html="item.top_candidate?item.top_candidate.factor_string:''"></div></template> -->
			<template v-slot:item.top_candidate_val="{ item }"><v-tooltip :disabled="!item.top_candidate" bottom><template v-slot:activator="{on}"><div v-on="on" class="d-flex"><v-spacer/>
				<div class="mr-2">{{(item.top_candidate_val == -1) ? '–' : item.top_candidate_val}}</div>
				<div style="width:100px"><v-progress-linear v-model="item.top_candidate_pct" :color="candidate_color(item.resource,item.top_candidate)" height="16"></v-progress-linear></div>
				<div v-visible="item.resource.candidate_params&&resource_suggestion_dirty(item.resource)" style="font-size:18px; margin-left:2px;">*</div>
			</div></template><div class="k-resource-factor-tooltip-outer" v-html="item.top_candidate?item.top_candidate.factor_string:''"></div></v-tooltip></template>

			<template v-slot:item.resource_title="{ item }">
				<v-hover v-slot:default="{hover}"><div class="d-flex align-center">
					<v-btn icon small :color="item.icon_color" dark class="mr-1 my-1" @click="go_to_resource(item.resource_id,$event)"><v-icon :style="item.icon_style" :class="hover?'fa-spin':''">fas fa-{{item.icon}}</v-icon></v-btn>
					<div class="ml-2">
						<a v-html="item.resource_title" @click="go_to_resource(item.resource_id,$event)"></a>
					</div>
				</div></v-hover>
			</template>
			<template v-slot:item.created_at="{ item }"><nobr style="font-size:12px">{{formatted_date(item.created_at)}}</nobr></template>
		</v-data-table>

		<div v-if="!viewing_resource" class="d-flex pb-4">
			<v-spacer/>
			<v-checkbox class="shrink mt-0 pt-0" hide-details v-model="show_resource_identifiers" label="Show resource identifiers"></v-checkbox>
			<v-spacer/>
		</div>
		
		<ResourceView v-if="viewing_resource" ref="resource_viewer" :set_component="this" :resource_set="resource_set" :table_filtered_resources="table_filtered_resources" :current_resource="current_resource" :batch_alignments_to_create="batch_alignments_to_create" :satchel_embed_width="satchel_embed_width" @show_table="show_table" @go_to_resource="go_to_resource" @new_resource_created="new_resource_created" @resource_removed="resource_removed" />
	</div></div>
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceView from './ResourceView'
import FrameworkSwitcher2 from '../frameworks/FrameworkSwitcher2'
import ResourceAlignmentMixin from './ResourceAlignmentMixin'

export default {
	components: { ResourceView, FrameworkSwitcher2 },
	mixins: [ResourceAlignmentMixin],
	props: {
		resource_set_id: { type: Number, required: true },
		resource_id: { type: Number, required: true },	// this will be 0 if we're viewing the table
		query: { type: Object, required: false, default() { return {} }},
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		footer_options: {
			itemsPerPageOptions: [20,50,100,-1],
		},
		table_options: {
			itemsPerPage: this.$store.state.lst.resource_set_list_items_per_page,
			page: 1,
		},
		current_page: 1,

		// for the resource viewer
		main_interface_width: 1000,
		satchel_embed_width: 600,

		search_text: '',
		search_re: null,
		cross_alignment_framework_records: [],
		n_alignments_to_tba_framework: 0,
		n_batch_alignments_to_create: 0,
		batch_alignments_hash: {},
		suggestions_to_compute: -1,

		// we use these three data items to pause computations of the "table_filtered_resources" and "batch_alignments_to_create" arrays when we're doing batch processing (see below),
		// because otherwise those computeds take up a lot of processing time for large resource sets
		tfr_compute_paused: false,
		cached_tfr_array: [],
		cached_batc: [],

		last_viewed_resource: null,

		item_type_examples: {},
	}},
	computed: {
		...mapState(['framework_records', 'user_info', 'site_config', 'resource_sets']),
		...mapGetters([]),
		resource_set() { return this.resource_sets.find(x=>x.resource_set_id == this.resource_set_id) },
		resources_loaded() { return this.resource_set?.resources_loaded },
		resources() { return this.resource_set?.resources },
		viewing_resource() { return this.resource_id > 0 },
		current_resource() { 
			if (!this.resources) return null
			return this.resources.find(x=>x.resource_id == this.resource_id) 
		},
		current_resource_index() { 
			if (!this.resources) return null
			return this.table_filtered_resources.findIndex(x=>x.resource_id == this.resource_id)
		},
		can_view_this_resource_set() { 
			// for now we'll assume that if you're here you can view the resource set
			return true
		},
		can_create_new() { 
			// for now we'll assume that if you're here you can create new resources
			return true
		},
		suggestions_progress() {
			if (this.table_filtered_resources.length == 0 || this.suggestions_to_compute == -1) return 100
			return (this.table_filtered_resources.length - this.suggestions_to_compute) / this.table_filtered_resources.length * 100
		},
		table_sort_by: {
			get() { return this.$store.state.lst.resource_set_table_sort_by },
			set(val) { this.$store.commit('lst_set', ['resource_set_table_sort_by', val]) }
		},
		table_sort_desc: {
			get() { return this.$store.state.lst.resource_set_table_sort_desc },
			set(val) { this.$store.commit('lst_set', ['resource_set_table_sort_desc', val]) }
		},
		show_aligned_resources: {
			get() { return this.$store.state.lst.resource_set_show_aligned_resources },
			set(val) { this.$store.commit('lst_set', ['resource_set_show_aligned_resources', val]) }
		},
		show_unaligned_resources: {
			get() { return this.$store.state.lst.resource_set_show_unaligned_resources },
			set(val) { this.$store.commit('lst_set', ['resource_set_show_unaligned_resources', val]) }
		},
		show_resource_identifiers: {
			get() { return this.$store.state.lst.resource_set_show_resource_identifiers },
			set(val) { this.$store.commit('lst_set', ['resource_set_show_resource_identifiers', val]) }
		},
		batch_align_settings_showing: {
			get() { return this.$store.state.lst.resource_set_batch_align_settings_showing_hash[this.resource_set_id] },
			set(val) { this.$store.commit('lst_set_hash', ['resource_set_batch_align_settings_showing_hash', this.resource_set_id, val]) }
		},
		batch_max_alignments_is_set: {
			get() { 
				return (this.$store.state.lst.resource_set_batch_max_alignments_hash[this.resource_set_id] != -1) 
			},
			set(val) { 
				if (!val) this.$store.commit('lst_set_hash', ['resource_set_batch_max_alignments_hash', this.resource_set_id, -1])
				else this.$store.commit('lst_set_hash', ['resource_set_batch_max_alignments_hash', this.resource_set_id, 1])
			}
		},
		batch_max_alignments: {
			get() { return this.$store.state.lst.resource_set_batch_max_alignments_hash[this.resource_set_id] ?? -1 },
			set(val) { this.$store.commit('lst_set_hash', ['resource_set_batch_max_alignments_hash', this.resource_set_id, val]) }
		},
		batch_simscore_threshold: {
			get() { return this.$store.state.lst.resource_set_batch_simscore_threshold_hash[this.resource_set_id] ?? 850 },
			set(val) { this.$store.commit('lst_set_hash', ['resource_set_batch_simscore_threshold_hash', this.resource_set_id, val]) }
		},
		wrapper_css() {
			let s = ''
			if (this.viewing_resource) {
				s += ' k-resource-set-viewing-resource'
				if (vapp.$refs.satchel.show_satchel) s += ' k-resource-set-viewing-resource-embedded-showing'
			}
			return s
		},
		wrapper_style() {
			if (!this.viewing_resource) return ''
			return `width:${this.main_interface_width}px;`
		},
		table_headers() {
			let arr = [{ text: 'Resource Title', value: 'resource_title', sortable: true, align: 'left' }]
			if (this.tba_framework_identifier) {
				arr.push({ text: 'Top Suggestion', value: 'top_candidate_val', sortable: true, class: 'k-resource-set-table-min', align: 'center' })
				arr.push({ text: 'TBA Alignments', value: 'n_tba_alignments', sortable: true, class: 'k-resource-set-table-min', align: 'center' })
				arr.push({ text: 'Cross-Alignments', value: 'n_cross_alignments', sortable: true, class: 'k-resource-set-table-min', align: 'center' })

			} else {
				arr.push({ text: 'Alignments', value: 'n_alignments', sortable: true, class: 'k-resource-set-table-min', align: 'center' })
			}
			if (this.show_resource_identifiers) arr.push({ text: 'ID', value: 'external_resource_id', sortable: true, class: 'k-resource-set-table-min', align: 'left' })
			arr.push({ text: 'Created At', value: 'created_at', sortable: true, class: 'k-resource-set-table-min', align: 'center' })
			return arr
		},
		n_resource_alignments() {
			// this.report_ms('  n_resource_alignments start')
			// do this in a separate computed so we don't have to re-do these counts every time we change filters
			let arr = []
			let n_alignments_to_tba_framework = 0
			let cross_alignment_framework_records = []	// this will hold a list of all the framework_records for which we have alignments
			for (let r of this.resources) {
				let o = {
					n_tba_alignments: 0,
					n_cross_alignments: 0,
					n_alignments: 0,
				}
				if (this.tba_framework_identifier) {
					for (let alignment of r.alignments) {
						if (alignment.framework_identifier == this.tba_framework_identifier) {
							++o.n_tba_alignments
						} else {
							// if a base framework is specified, only count cross_alignments here for the base framework
							if (empty(this.base_framework_identifier) || this.base_framework_identifier == alignment.framework_identifier) {
								++o.n_cross_alignments
							}
							
							// but compile cross_alignment_framework_records for all alignments
							if (!cross_alignment_framework_records.find(x=>x.lsdoc_identifier == alignment.framework_identifier)) {
								cross_alignment_framework_records.push(this.framework_records.find(x=>x.lsdoc_identifier == alignment.framework_identifier))
							}
						}
					}

					if (o.n_tba_alignments > 0) ++n_alignments_to_tba_framework
				} else {
					o.n_alignments = r.alignments.length
				}
				arr.push(o)
			} 
			this.cross_alignment_framework_records = cross_alignment_framework_records
			this.n_alignments_to_tba_framework = n_alignments_to_tba_framework
			// this.report_ms('  n_resource_alignments end')
			return arr
		},
		table_filtered_resources() {
			if (this.tfr_compute_paused || this.viewing_resource) {
				if (this.debug) console.warn('!! tfr paused')
				return this.cached_tfr_array
			}

			this.report_ms('table_filtered_resources start')
			// console.warn('table_filtered_resources')
			// if (empty(this.n_resource_alignments)) return []
			let arr = []
			for (let i = 0; i < this.resources.length; ++i) {
				let r = this.resources[i]

				// if we have a search_re, filter by it
				if (!empty(this.search_re)) {
					if (r.resource_title.search(this.search_re) == -1) continue
				}

				let o = {
					resource_index: i,
					n_cross_alignments: this.n_resource_alignments[i].n_cross_alignments,
					n_alignments: this.n_resource_alignments[i].n_alignments,
					top_candidate: null,
					top_candidate_val: -1,
					top_candidate_pct: 0,
				}

				// TODO: clean this up...
				if (false && this.batch_alignments_hash[r.resource_id]) o.n_tba_alignments = this.batch_alignments_hash[r.resource_id]?.alignments.length
				else o.n_tba_alignments = this.n_resource_alignments[i].n_tba_alignments

				// if we have a tba_framework_identifier, do some extra calculations
				if (this.tba_framework_identifier) {
					if (!this.show_aligned_resources && o.n_tba_alignments > 0) continue
					if (!this.show_unaligned_resources && o.n_tba_alignments == 0) continue

					// get top candidate
					if (r.candidates.length > 0) {
						o.top_candidate = r.candidates[0]
						o.top_candidate_val = r.candidates[0].simscore
						o.top_candidate_pct = r.candidates[0].simscore_pct
					}

					// TODO: maybe add this.batch_alignments_hash[r.resource_id]?.alignments.length to top_candidate_val so that items with lots of batch suggestions get listed first when we sort by top_candidate_val??
					
					// TODO: note if the suggestion is out-of-date
				}	
				
				o.resource = r
				o.resource_id = r.resource_id
				o.external_resource_id = r.external_resource_id
				o.resource_title = r.resource_title
				o.created_at = r.created_at
				o.icon = r.random_icon()
				o.icon_color = r.random_color()
				o.icon_style = `transform:rotate(0deg)`
				// o.icon_style = `transform:rotate(${r.random_icon_rotation()}deg)`
				o.n_alignments = r.alignments.length

				// indicate when the number of TBA alignments differs from the number of cross-alignments, if we're using a base
				o.cross_alignment_cell_class = ''
				if (this.base_framework_identifier) {
					if (o.n_cross_alignments < o.n_tba_alignments) o.cross_alignment_cell_class = 'blue darken-3 white--text px-1'
					else if (o.n_cross_alignments > o.n_tba_alignments) o.cross_alignment_cell_class = 'orange darken-3 white--text px-1'
				}
				
				arr.push(o)
			}

			// console.log('sorting by ' + this.table_sort_by)
			arr.sort((a,b)=>{
				// let x = U.natural_sort(a[this.table_sort_by]+'', b[this.table_sort_by]+'')
				let x = (a[this.table_sort_by] < b[this.table_sort_by]) ? -1 : (a[this.table_sort_by] > b[this.table_sort_by]) ? 1 : 0
				if (this.table_sort_desc) x *= -1
				return x
			})

			this.report_ms('table_filtered_resources end')
			this.cached_tfr_array = arr
			return arr
		},

		batch_align_available() {
			return this.resources.findIndex(x=>x.candidates.length > 0) > -1
			// return this.suggestions_to_compute == 0
		},

		batch_alignments_to_create() {
			if (this.tfr_compute_paused) return this.cached_batc

			if (!this.batch_align_available || !this.batch_align_settings_showing) {
				this.n_batch_alignments_to_create = 0
				this.batch_alignments_hash = {}
				return []
			}

			this.report_ms('batch_alignments_to_create start')
			let n_total_alignments = 0
			let resources_to_save = []
			let hash = {}
			for (let row of this.table_filtered_resources) {
				let resource = row.resource
				let r = {
					resource_id: resource.resource_id,
					alignments: [],
				}
				for (let candidate of resource.candidates) {
					if ((this.batch_max_alignments != -1 && r.alignments.length >= this.batch_max_alignments) || candidate.simscore < this.batch_simscore_threshold) break
					if (candidate.currently_aligned) continue
					r.alignments.push({
						fi: this.tba_framework_identifier,
						ii: candidate.cfitem.identifier,
					})
					// TODO: deal with simscore ties??
				}
				if (r.alignments.length > 0) {
					resources_to_save.push(r)
					n_total_alignments += r.alignments.length
					hash[resource.resource_id] = r
				}
			}
			this.n_batch_alignments_to_create = n_total_alignments
			this.batch_alignments_hash = hash
			this.report_ms('batch_alignments_to_create end')

			this.cached_batc = resources_to_save
			return resources_to_save
		},
		base_framework_options() {
			let arr = [{value:'', text:'NONE (no base framework)'}]
			for (let fr of this.cross_alignment_framework_records) {
				arr.push({value: fr.lsdoc_identifier, text: U.framework_title_with_category(fr)})
			}
			return arr
		},
		items_per_page: {
			get() { return this.$store.state.lst.resource_set_list_items_per_page },
			set(val) { this.$store.commit('lst_set', ['resource_set_list_items_per_page', val]) }
		},
	},
	watch: {
		tba_framework_identifier() {
			// console.warn('tba_framework_identifier changed')
			this.reset_all_resource_alignment_suggestions()
		},
		'$vuetify.breakpoint.width': {immediate: true, handler() {
			// calculate widths for main interface and chooser
			// need 20px of padding for interface
			// min width for chooser is 460px, but we'd like to give it 600px if possible
			// we would prefer the main interface to be at least 900px
			// if width is > 1600px, just give the chooser its max width and let the main interface have the rest
			this.satchel_embed_width = Math.round((600/1600) * (this.$vuetify.breakpoint.width - 20))
			if (this.satchel_embed_width > 600) this.satchel_embed_width = 600
			if (this.satchel_embed_width < 460) this.satchel_embed_width = 460
			this.main_interface_width = this.$vuetify.breakpoint.width - 20 - this.satchel_embed_width
			// console.log(`width: ${this.main_interface_width} / ${this.satchel_embed_width} ($vuetify.breakpoint.width)`)
		}},
		current_resource: {immediate: true, handler() {
			if (empty(this.current_resource)) {
				// when current_resource goes to empty, the table has been revealed, so if we have a last_viewed_resource...
				if (!empty(this.last_viewed_resource)) {
					// make sure the proper "page" of the table is showing for the last-viewed resource
					let index = this.table_filtered_resources.findIndex(x=>x.resource == this.last_viewed_resource)
					if (index > -1) this.current_page = Math.floor(index / this.items_per_page) + 1

					// then scroll to the lvr in the table
					setTimeout(x=>{
						this.$vuetify.goTo($('.k-resource-set-last-viewed-resource')[0], {container:$(this.$el).find('.k-framework-list-wrapper-inner')[0], offset:0})
					}, 100)
				}
			} else {
				this.last_viewed_resource = this.current_resource
				$(this.$el).find('.k-framework-list-wrapper-inner').scrollTop(0)
			}
		}},
	},
	created() {
		this.report_ms('created')
		// if you're not allowed to be in the resources viewer, redirect to the framework list
		if (!vapp.show_resources_toggle || !this.can_view_this_resource_set) {
			vapp.go_to_route('frameworks')
			return
		}
		// else make sure frameworks_or_resources_toggle is set to 'resources'
		this.$store.commit('lst_set', ['frameworks_or_resources_toggle', 'resources'])

		this.$store.dispatch('get_resource_sets').then(x=>{
			if (!this.resources_loaded) this.$store.dispatch('get_resource_set_resources', this.resource_set_id)
		})

		vapp.resource_set_component = this
	},
	mounted() {
	},
	methods: {
		// we need to keep track of table pagination for the current_resource scrolling to work (see above)
		pagination_changed(o) {
			// console.warn('pagination_changed', o)
			this.items_per_page = o.itemsPerPage
			this.current_page = o.page
		},

		get_item_type_example(item_type) {
			let keys = Object.keys(this.tba_framework_record.cfo.cfitems)
			U.shuffle_array(keys)
			for (let key of keys) {
				let cfi = this.tba_framework_record.cfo.cfitems[key]
				if (U.item_type_string(cfi) == ((item_type == '[no item type]') ? '' : item_type)) {
					this.$set(this.item_type_examples, item_type, U.generate_cfassociation_node_uri_title(cfi, 150, true))
					return
				}
			}
			this.$set(this.item_type_examples, item_type, '???')
		},

		handle_search() {
			// establish the debounce fn if necessary
			if (empty(this.handle_search_debounced)) {
				this.handle_search_debounced = U.debounce(() => {
					console.log('handle_search_debounced')
					this.search_text = $.trim(this.search_text)
					if (empty(this.search_text)) {
						this.search_re = null
						return
					}
					this.search_re = new RegExp(this.search_text, 'i')
				}, 500)
			}
			// call the debounce fn
			this.handle_search_debounced()
		},
		formatted_date(s) {
			s += '+00:00'	// add GMT timezone indicator, then use U.local_last_change_date_time
			return U.local_last_change_date_time(s)
		},

		candidate_color(resource, candidate) {
			if (candidate?.currently_aligned) return 'orange darken-3'
			if (this.batch_alignments_to_create.find(x=>x.resource_id==resource.resource_id)) return 'pink darken-3'
			return 'grey darken-4'
		},

		rename_set() {
			this.$prompt({
				title: 'Rename Resource Set',
				text: 'Enter the new name for this resource set:',
				initialValue: this.resource_set.resource_set_title,
				disableForEmptyValue: true,
				acceptText: 'Save',
				acceptIcon: 'fas fa-save',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return
				this.$store.commit('set', [this.resource_set, 'resource_set_title', title])

				let payload = {
					service_url: 'save_resource_set',
					user_id: this.user_info.user_id,
					resource_set_id: this.resource_set.resource_set_id,
					resource_set_data: {resource_set_title: this.resource_set.resource_set_title},
				}
				U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					U.loading_stop()
					if (!result.resource_set) {
						console.log(result)
						this.$alert('save_resource_set service failed')
					}
				}).catch(result=>{
					U.loading_stop()
					this.$alert('Error: ' + result?.status)
				})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		add_set_editor() {
			this.$prompt({
				title: 'Add an Editor',
				text: 'Enter the email address for the person to make an editor for this Resource Set:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				initialValue: '',
				acceptText: 'Add Editor',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

				// if email is already in editor_emails, tell them
				if (this.resource_set.editor_emails.includes(email)) {
					this.$alert('That user is already an editor for this Resource Set.')
					return
				}

				// else add to editor_emails
				this.$store.commit('set', [this.resource_set.editor_emails, 'PUSH', email])

				let payload = {
					service_url: 'save_resource_set',
					user_id: this.user_info.user_id,
					resource_set_id: this.resource_set.resource_set_id,
					resource_set_data: {editor_emails: this.resource_set.editor_emails},
				}
				U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					U.loading_stop()
					if (!result.resource_set) {
						console.log(result)
						this.$alert('save_resource_set service failed')
					}
				}).catch(result=>{
					U.loading_stop()
					this.$alert('Error: ' + result?.status)
				})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		go_to_resource(which, $event) {
			if (which == 'prev') {
				if (this.current_resource_index > 0) which = this.table_filtered_resources[this.current_resource_index - 1].resource_id
			} else if (which == 'next') {
				if (this.current_resource_index < this.table_filtered_resources.length-1) which = this.table_filtered_resources[this.current_resource_index + 1].resource_id
			}

			if (typeof(which) == 'number') {
				let url = `/resources/${this.resource_set_id}/${which}`
				this.$router.push({ path: url })
			} else {	// shouldn't happen
				console.error('go_to_resource: bad value for which: ', which)
			}
		},

		show_table(flag) {
			// if the user is editing or creating a new resource, ask ResourceEdit to check for changes
			if (flag !== 'confirmed' && this.$refs.resource_viewer?.editing_resource) {
				this.$refs.resource_viewer.edit_resource_cancel('show_table')
				return
			}
			if (this.resource_id == 1) {
				// if they filled anything in, ask if they want to save
				// otherwise kill that resource
				let i = this.resources.findIndex(x=>x.resource_id == 1)
				if (i > -1) {
					this.$store.commit('set', [this.resources, 'SPLICE', i])
				}
			}
			let url = `/resources/${this.resource_set_id}`
			this.$router.push({ path: url })
		},

		create_new_resource() {
			if (this.$refs.resource_viewer?.editing_resource) {
				this.$alert('Please finish editing the current resource (or cancel) before creating a new resource.')
				return
			}

			// actual resource_id's start at 1000, so we'll know that resource_id 1 needs to be saved
			let r = new Resource({
				resource_id: 1, 
				resource_set_id: this.resource_set_id, 
				creator_user_id: this.user_info.user_id
			})
			this.$store.commit('set', [this.resources, 'PUSH', r])
			this.go_to_resource(1)
		},

		new_resource_created(resource_data) {
			let i = this.resources.findIndex(x=>x.resource_id == 1)
			if (i > -1) {	// should always be true
				let r = new Resource(resource_data)
				this.$store.commit('set', [this.resources, 'SPLICE', i, r])
				this.go_to_resource(r.resource_id)
			}
		},

		resource_removed(resource_id) {
			this.show_table('confirmed')
			this.$nextTick(x=>{
				let index = this.resource_set.resources.findIndex(x=>x.resource_id == resource_id)
				if (index == -1) this.$alert('ERROR: couldn’t find index of resource in resource_set')	// shouldn't happen
				else this.$store.commit('set', [this.resource_set.resources, 'SPLICE', index])
			})
		},

		start_resource_import() {
			//////////////////////////////////
			// Prompt for import file

			this.$prompt({
				title: 'Import Resources',
				text: 'Choose a CSV- or TSV-formatted file specifying the resources you wish to align. When you click the “PROCESS FILE” button, the file will be evaluated, and you’ll be asked to confirm that you wish to import the resources found in the file.',
				// text: 'Choose a CSV- or TSV-formatted file specifying the resources you wish to align. For each resource, you must specify at least a resource ID. You may also specify a title, description, extra text, URL, and education level. When you click the “PROCESS FILE” button, the file will be evaluated, and you’ll be asked to confirm that you wish to import the resources found in the file.',
				promptType: 'file',
				acceptText: 'Process File',
				dialogMaxWidth: 550,
			}).then(file => {
				if (empty(file) || empty(file.name)) return
				// we receive a file from dialog-promise-pwet here; create a FileReader
				let reader = new FileReader()

				//////////////////////////////////
				// Read file and parse resource rows

				reader.onload = e => {
					let text = e.target.result 

					this.process_input_file(text)
				}
				// trigger the FileReader to load the text of the file
				reader.readAsText(file)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		process_input_file(file_text) {
			// DEBUG
			if (empty(file_text)) file_text = window.file_text
			window.file_text = file_text

			let associations_for = {}

			// parse file_text; if first line includes a tab, assume it's a TSV; otherwise assume CSV
			let table
			if (file_text.split('\n')[0].indexOf('\t') > -1) {
				table = TSV.parse(file_text)
			} else {
				table = CSV.parse(file_text)
			}
			// console.log(table)

			if (table.length == 1) {
				this.$alert('The uploaded file included only one line. A resource import must include at least one line of headers and at least one line specifying a resource.')
				return
			}

			/* "Schema" for imports:
				resource_id
				title
				description
				keywords
				url
				educationLevel
				cfdocument_identifier
				cfitem_identifier
			*/

			// parse headers in line 0
			let fields = []
			for (let original_val of table[0]) {
				let val = original_val.toLowerCase()
				let field
				if (val == 'id' || val == 'resource_id' || val == 'external_resource_id') field = 'external_resource_id'
				else if (val == 'cfdocument_identifier') field = 'cfdocument_identifier'
				else if (val == 'cfitem_identifier') field = 'cfitem_identifier'
				else if (val.indexOf('title') > -1) field = 'resource_title'
				else if (val.indexOf('description') > -1) field = 'description'
				else if (val.indexOf('text') > -1 || val.indexOf('keywords') > -1) field = 'text'
				else if (val.indexOf('url') > -1) field = 'url'
				else if (val.indexOf('level') > -1) field = 'educationLevel'
				else if (val.indexOf('alignments:') > -1) field = val		// 'alignments:' followed by a framework_id
				else if (val.indexOf('alignment') > -1) field = 'alignments'
				if (empty(field)) {
					this.$alert(sr('Unknown field specified in header row: “$1”', original_val))
					return
				}
				fields.push(field)
			}
			console.log(fields)

			// now parse resource rows: for each non-header row...
			let resources = []
			for (let i = 1; i < table.length; ++i) {
				if (empty(table[i].join(''))) {
					console.log('empty row')
					continue
				}
				let r = {
					resource_set_id: this.resource_set_id,
				}
				// for each column in the row...
				for (let j = 0; j < table[i].length; ++j) {
					// if value isn't empty, set that column's field to the value
					if (!empty(table[i][j])) {
						r[fields[j]] = table[i][j]
					}
				}

				// at the least, an ID must be specified
				if (empty(r.external_resource_id)) {
					console.log(r)
					this.$alert(`The resource in row ${i} does not have an ID specified. A resource ID must be specified for each resource.`)
					return
				}

				// educationLevel must be an array; allow for comma-separated values
				if (r.educationLevel) {
					r.educationLevel = r.educationLevel.split(/\s*,\s*/)

					// if a single value, see if it's a range, and split if so
					if (r.educationLevel.length == 1) {
						let arr = U.grade_level_display_to_educationLevel(r.educationLevel[0])
						if (arr.length > 0) r.educationLevel = arr
					}
				}

				// check format for/parse `alignments` column if we have it; format for this should be:
				// framework_identifier_1:item_identifier_1, framework_identifier_1:item_identifier_2, framework_identifier_2:item_identifier_3
				if (r.alignments) {
					let aarr = r.alignments.split(/\s*,\s*/)
					r.alignments = []
					for (let j = 0; j < aarr.length; ++j) {
						let arr = aarr[j].split(/\s*:\s*/)
						if (arr.length != 2) {
							this.$alert(`Improperly formatted alignments for resource ID ${r.external_resource_id}: ${aarr[j]}`)
							return
						}
						let framework_identifier = arr[0]
						let item_identifier = arr[1]

						// don't push duplicate alignments
						if (r.alignments.find(x=>x.fi == framework_identifier && x.ii == item_identifier)) {
							console.warn('skipping duplicate alignment (1)', item_identifier)
							continue
						}

						// TODO: if item_identifier isn't a guid, assume it's a humanCodingScheme, and try to find it in the given framework
						r.alignments.push({fi: framework_identifier, ii: item_identifier})
					}
				}

				// check for/parse cfdocument_identifier/cfitem_identifier columns for importing alignments
				if (r.cfdocument_identifier && r.cfitem_identifier) {
					if (empty(r.alignments)) r.alignments = []
					// don't push duplicate alignments
					if (r.alignments.find(x=>x.fi == framework_identifier && x.ii == item_identifier)) {
						console.warn('skipping duplicate alignment (1)', item_identifier)
						continue
					}

					// TODO: if item_identifier isn't a guid, assume it's a humanCodingScheme, and try to find it in the given framework
					r.alignments.push({fi: r.cfdocument_identifier, ii: r.cfitem_identifier})
					delete r.cfdocument_identifier
					delete r.cfitem_identifier
				}

				// if we got `alignments:xxx` columns, format those
				for (let f of fields) {
					if (f.indexOf('alignments:') > -1) {
						if (!empty(r[f])) {
							let framework_identifiers = f.replace(/alignments:\s*/, '').split(/\s*,\s*/)
							for (let framework_identifier of framework_identifiers) {
								let framework_record = this.framework_records.find(x=>x.lsdoc_identifier == framework_identifier)

								if (empty(framework_record)) {
									this.$alert(`Your import file specifies alignments to a framework with GUID ${framework_identifier}, but this framework could not be identified.`)
									return
								}

								// if we don't already have this framework fully loaded, load and re-process
								if (!framework_record.framework_json_loaded && !framework_record.framework_json_loading) {
									this.load_framework(framework_identifier).then(()=>{
										this.process_input_file(file_text)
									}).catch(result=>{
										console.error('error getting framework', result)
									})
									return
								}

								if (empty(associations_for[framework_identifier])) associations_for[framework_identifier] = 0

								let aarr = r[f].split(/\s*,\s*/)
								if (empty(r.alignments)) r.alignments = []
								for (let item_identifier of aarr) {
									// if it's not a GUID, assume it's a HCS value, and try to look it up
									if (!U.is_uuid(item_identifier)) {
										let item = framework_record.json.CFItems.find(x=>x.humanCodingScheme == item_identifier)
										if (item) {
											item_identifier = item.identifier
										} else {
											console.warn(`no item found in ${framework_identifier} for hcs: ${item_identifier}`)
											continue
										}
									
									// else look up in cfitems hash
									} else if (empty(framework_record.cfo.cfitems[item_identifier])) {
										// console.warn(`no item found in ${framework_identifier} for GUID: ${item_identifier}`)
										continue
									}
									// console.log(`found associated item for ${framework_identifier} / ${item_identifier}`)

									// don't push duplicate alignments
									if (r.alignments.find(x=>x.fi == framework_identifier && x.ii == item_identifier)) {
										console.warn('skipping duplicate alignment (2)', item_identifier)
										continue
									}

									r.alignments.push({fi: framework_identifier, ii: item_identifier})
									++associations_for[framework_identifier]
								}
							}
						}
						delete r[f]
					}
				}

				// if we get a resource ID from earlier in this import file, add to it; this lets us use different lines for alignments
				let existing = resources.find(x=>x.external_resource_id == r.external_resource_id)

				// if this is a new resource (in this file)...
				if (!existing) {
					// if we don't have a title, make one up, unless the resource already exists in the set
					if (!r.resource_title) {
						if (!this.resources.find(x=>x.external_resource_id == r.external_resource_id)) {
							// OR skip it if it doesn't have a title...
							continue
							r.resource_title = `resource ${r.external_resource_id}`
						}
					}

					// then push to resources
					resources.push(r)
				
				// else it already exists in the resources array; update in place
				} else {
					// update existing with the fields from r, merging alignments if there
					for (let key in r) {
						if (key == 'alignments') {
							if (empty(existing.alignments)) existing.alignments = []
							for (let a of r.alignments) {
								// don't push duplicate alignments
								if (existing.alignments.find(x=>x.fi == a.fi && x.ii == a.ii)) {
									console.warn('skipping duplicate alignment (3)', r.resource_id, a.ii)
									continue
								}
								existing.alignments.push(a)
							}
						} else {
							existing[key] = r[key]
						}
					}
				}
			}

			//////////////////////////////////
			// send resources to the server to check if any have been already imported
			console.log('associations_for', associations_for)

			U.loading_start()
			let payload = {
				service_url: 'save_resources',
				// we stringify the resources in case it's a lot of data
				resources: JSON.stringify(resources),
				user_id: this.user_info.user_id,
				framework_identifier: this.framework_identifier,
				check_only: 'yes',
				return_resources: 'no',
				return_resource_alignments: 'no',
				return_raw_alignments: 'no',
			}
			this.$store.dispatch('service', payload).then((result)=>{
				U.loading_stop()

				//////////////////////////////////
				// prepare a brief report for the resources that would be uploaded
				let new_resource_ids = []
				let updated_resource_ids = []
				for (let external_resource_id in result.resource_status) {
					if (result.resource_status[external_resource_id] == 'new') {
						new_resource_ids.push(external_resource_id)
					} else {
						updated_resource_ids.push(external_resource_id)
					}
				}

				console.warn(payload)
				console.warn(result)

				let html = ''
				if (new_resource_ids.length > 0) {
					html += `<div class="mb-3">${new_resource_ids.length} ${U.ps('resource', new_resource_ids.length)} will be <b>created</b></div>`
				}
				if (updated_resource_ids.length > 0) {
					html += `<div class="mb-3">${updated_resource_ids.length} ${U.ps('resource', updated_resource_ids.length)} will be <b>updated</b></div>`
				}
				
				if (empty(html)) {
					this.$alert(`Something is malformed in the file you attempted to import from: no new resources or resource updates could be parsed.`)
					return
				}

				html += 'Would you like to proceed?'

				this.$confirm({
					title: 'Import Resources',
					text: html,
					acceptText: 'Import Resources',
					dialogMaxWidth: 600
				}).then(y => {
					//////////////////////////////////
					// send resources back to the server to be imported

					U.loading_start()
					let payload = {
						service_url: 'save_resources',
						// we stringify the resources in case it's a lot of data
						resources: JSON.stringify(resources),
						user_id: this.user_info.user_id,
						framework_identifier: this.framework_identifier,
						check_only: 'no',
						return_resources: 'yes',
						return_resource_alignments: 'yes',
						return_raw_alignments: 'no',
					}
					this.$store.dispatch('service', payload).then((result)=>{
						U.loading_stop()

						//////////////////////////////////
						// add/replace resources to the resource_set
						for (let resource of result.resources) {
							resource = new Resource(resource)
							let i = this.resources.findIndex(x=>x.resource_id == resource.resource_id)
							if (i == -1) this.$store.commit('set', [this.resources, 'PUSH', resource])
							else this.$store.commit('set', [this.resources, 'SPLICE', i, resource])
						}

					}).catch((result)=>{
						U.loading_stop()
						this.$alert('<b class="red--text">An error occurred:</b> ' + result.error)
					}).finally(()=>{})

				}).catch(n=>{console.log(n)}).finally(f=>{})

			}).catch((result)=>{
				U.loading_stop()
				this.$alert('<b class="red--text">An error occurred:</b> ' + result.error)
			}).finally(()=>{})
		},

		start_resource_export() {
			// for now, only export the alignments for the currently-selected framework
			let arr = []

			// header row
			arr.push(['resource_id', 'cfdocument_identifier', 'cfitem_identifier'])
			for (let i = 0; i < this.resources.length; ++i) {
				let r = this.resources[i]
				for (let alignment of r.alignments) {
					if (alignment.framework_identifier == this.tba_framework_identifier) {
						arr.push([r.external_resource_id, alignment.framework_identifier, alignment.item_identifier])
					}
				}
			}
			// console.log(CSV.stringify(arr))

			let fr = this.framework_records.find(x=>x.lsdoc_identifier == this.tba_framework_identifier)
			let framework_title = fr.json.CFDocument.title
			if (arr.length == 0) {
				this.$alert(`No alignments to export for framework “${framework_title}”.`)
				return
			}

			this.$confirm({
				title: 'Export Alignments',
				text: `This will export a CSV file with ${arr.length - 1} alignments to the currently-selected framework (“${framework_title}”).`
					+ `<div class="mt-2">The first line of the CSV file will be a header specifying the three columns in the file:<ol>`
					+ `<li><b>resource_id</b>: a resource id (in the format you used when you uploaded the resources of this set)</li>`
					+ `<li><b>cfdocument_identifier</b>: the CASE identifier (GUID) for the to-be-aligned framework’s document</li>`
					+ `<li><b>cfitem_identifier</b>: the CASE identifier (GUID) for the aligned standard in this framework</li>`
					+ `</ol></div>`
					+ `<div class="mt-2">Note that the same resource_id will have multiple rows if that resource is aligned to multiple standards.</div>`,
				acceptText: 'Download Export File',
				acceptIconAfter: 'fas fa-file-export',
				dialogMaxWidth: 800,
			}).then(y => {
				let date_string = date.format(new Date(), 'YYYY-MM-DD')
				let filename = `${this.resource_set.resource_set_title}--Alignments--${framework_title}--${date_string}.csv`
				// console.log(filename)
				U.download_file(CSV.stringify(arr), filename)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		get_all_resource_alignment_suggestions() {
			if (this.limiters.item_types.length == 0) {
				this.$alert('To compute suggestions, you must choose one or more item types to align to.')
				return
			}

			this.tfr_compute_paused = true

			// console.log(this.table_sort_by)
			let suggest = (index) => {
				let resource = (this.table_sort_by != 'top_candidate_val') ? arr[index].resource : arr[index]
				// skip resources that don't need to be updated
				while (this.suggestions_to_compute > 0 && resource && !this.resource_suggestion_dirty(resource)) {
					++index
					resource = (this.table_sort_by != 'top_candidate_val') ? arr[index]?.resource : arr[index]
					--this.suggestions_to_compute
				}
				if (this.suggestions_to_compute == 0) return

				this.get_resource_alignment_suggestions(resource, this.tba_framework_identifier).finally(x=>{
					--this.suggestions_to_compute
					if (this.suggestions_to_compute > 0) {
						setTimeout(x=>suggest(index + 1))
					} else {
						this.tfr_compute_paused = false
					}
				})
			}

			// if we're sorted by top_candidate_val, we can't go in the table row order, because the rows will move around as we compute values
			let arr = (this.table_sort_by != 'top_candidate_val') ? this.table_filtered_resources : this.resources
			this.suggestions_to_compute = arr.length

			// reset all dirty suggestions before we start
			this.reset_all_resource_alignment_suggestions('dirty_only')

			// this.suggestions_to_compute = 20
			suggest(0)
		},

		reset_all_resource_alignment_suggestions(flag) {
			for (let resource of this.resources) {
				if (flag != 'dirty_only' || this.resource_suggestion_dirty(resource)) {
					resource.candidate_params = null
					resource.candidates = []
				}
			}
		},

		cancel_get_all_suggestions() {
			this.suggestions_to_compute = -1
			this.tfr_compute_paused = false
		},

		batch_align_start_clicked($event) {
			// hidden functionality: hold shift key down while clicking batch btn to batch-unalign
			if ($event.shiftKey) {
				this.batch_unalign_all()
				return
			}

			if (!this.batch_align_available) {
				this.$alert('You haven’t computed any suggestions!')
				return
			}
			this.batch_align_settings_showing = true
		},

		batch_unalign_all() {
			let arr = []
			let n = 0
			for (let resource of this.resources) {
				if (resource.alignments.length > 0) {
					let rdata = {
						resource_id: resource.resource_id,
						alignments: []
					}
					let found_one = false
					for (let alignment of resource.alignments) {
						// we only clear alignments from the tba framework
						if (alignment.framework_identifier != this.tba_framework_identifier) continue
						rdata.alignments.push({clear: alignment.resource_alignment_id})
						++n
						found_one = true
					}
					if (found_one) arr.push(rdata)
				}
			}

			console.log(arr)
			if (n == 0) {
				this.$alert('No alignments to clear.')
				return
			}

			this.$confirm({
				title: 'Are you sure?',
				text: `Are you sure you want to batch remove ${n==1?'this alignment':'these ' + n + ' alignments'}?`,
				acceptText: ((n == 1) ? 'Remove Alignment' : 'Remove Alignments'),
				acceptColor: 'pink darken-3',
				acceptIcon: 'fas fa-wand-magic-sparkles',
			}).then(y => {
				let payload = {
					service_url: 'save_resources',
					resources: JSON.stringify(arr),
					user_id: this.user_info.user_id,
					return_resources: 'no',
					return_resource_alignments: 'no',
					return_raw_alignments: 'no',
				}
				U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					U.loading_stop()

					// remove alignments from the resources
					for (let rdata of arr) {
						let resource = this.resources.find(x=>x.resource_id == rdata.resource_id)
						for (let o of rdata.alignments) {
							let i = resource.alignments.findIndex(x=>x.resource_alignment_id == o.clear)
							let alignment = resource.alignments[i]
							if (i > -1) this.$store.commit('set', [resource.alignments, 'SPLICE', i])

							// also if the resource has candidates and this item is one of them, mark it as not currently aligned
							let candidate = resource.candidates.find(x=>x.cfitem.identifier == alignment?.item_identifier)
							if (candidate) this.$store.commit('set', [candidate, 'currently_aligned', false])
						}
					}

				}).catch((result)=>{
					U.loading_stop()
					this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
				}).finally(()=>{})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		do_batch_alignments() {
			if (this.n_batch_alignments_to_create == 0) return
			this.$confirm({
				title: 'Are you sure?',
				text: `Are you sure you want to batch-create ${this.n_batch_alignments_to_create==1?'this alignment':'these ' + this.n_batch_alignments_to_create + ' alignments'}?`,
				acceptText: ((this.n_batch_alignments_to_create == 1) ? 'Create Alignment' : 'Create Alignments'),
				acceptColor: 'pink darken-3',
				acceptIcon: 'fas fa-wand-magic-sparkles',
			}).then(y => {
				let payload = {
					service_url: 'save_resources',
					resources: JSON.stringify(this.batch_alignments_to_create),
					user_id: this.user_info.user_id,
					return_resources: 'no',
					return_resource_alignments: 'no',
					return_raw_alignments: 'yes',
				}
				U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					U.loading_stop()

					// add alignments to the resources, based on new_alignments we get back from the service
					for (let new_alignment of result.new_alignments) {
						let ra = new Resource_Alignment(new_alignment)
						let resource = this.resources.find(x=>x.resource_id == ra.resource_id)
						if (!resource) {	// shouldn't happen
							console.error('do_batch_alignments: couldn’t find resource ' + ra.resource_id)
							continue
						}
						this.$store.commit('set', [resource.alignments, 'PUSH', ra])

						// also if the resource has candidates and this aligned item is one of them, mark it as currently aligned
						let candidate = resource.candidates.find(x=>x.cfitem.identifier == new_alignment.item_identifier)
						if (candidate) this.$store.commit('set', [candidate, 'currently_aligned', true])
					}

					this.$alert({title: 'Done', text:'Batch alignment complete!'})

				}).catch((result)=>{
					U.loading_stop()
					this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
				}).finally(()=>{})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		cache_suggestions() {
			// this would be for caching suggestions in localstorage so we don't have to compute when we come back...
			let hash = {}
			for (let resource of this.resources) {
				if (resource.candidates.length > 0 && !this.resource_suggestion_dirty(resource)) {
					let arr = []
					for (let candidate of resource.candidates) {
						arr.push({
							id: candidate.cfitem.identifier,
							comps: candidate.comps,
							simscore: candidate.simscore,
						})
						break
					}
					hash[resource.resource_id] = arr 
				}
			}
			let s = JSON.stringify(hash)
			console.warn('cache', s.length)
		},

		// vapp.resource_set_component.candidates_to_show_params.max_in_memory=1000
		// K-8:
		// vapp.resource_set_component.limiters.include_branches = ['26b40784-0d5e-11eb-bb8c-0242ac150004', '38609696-0d5e-11eb-a666-0242ac150004', '32f19f5c-9d63-11e7-a0b8-71e52e5326e7', 'fc4bbf16-9d60-11e7-bfe2-d75343e851d9', 'fc14bf8e-9d60-11e7-a730-f2791a4efa0e', '21d190c3-aac1-4d96-b9e1-a388cee71ff9']; vapp.resource_set_component.candidates_to_show_params.max_in_memory=1000
		// HS:
		// vapp.resource_set_component.limiters.include_branches = ['32f19f5c-9d63-11e7-a0b8-71e52e5326e7', 'fc4bbf16-9d60-11e7-bfe2-d75343e851d9', 'fc14bf8e-9d60-11e7-a730-f2791a4efa0e', '21d190c3-aac1-4d96-b9e1-a388cee71ff9']; vapp.resource_set_component.candidates_to_show_params.max_in_memory=1000
		// vapp.resource_set_component.validate_alignments()
		validate_alignments() {
			let stats = {
				resources: [],
				n_candidates: [],
				highest_ranks: [],
				highest_rank_scores: [],
				mean_rank_scores: [],
				highest_comp_scores: [],
				mean_comp_scores: []
			}
			let errors = []
			for (let resource of this.resources) {
				if (resource.candidates.length > 0) {
					let highest_comp_score = 0, sum_comp_scores = 0, n_matches = 0, indices = []
					for (let alignment of resource.alignments) {
						if (alignment.framework_identifier == this.tba_framework_identifier) {
							for (let i = 0; i < resource.candidates.length; ++i) {
								let c = resource.candidates[i]
								if (c.cfitem.identifier == alignment.item_identifier) {
									indices.push(i)
									let comp_score = c.comps.item
									if (comp_score > highest_comp_score) highest_comp_score = comp_score
									sum_comp_scores += comp_score
									++n_matches
									console.log(`Match candidate ${i+1}: ${resource.resource_title} - ${c.cfitem.humanCodingScheme} (${comp_score})`)
								}
							}
						}
					}
					if (highest_comp_score == 0) {
						errors.push(resource)
					} else {
						// calculate 0-100 "rank_score" values indicating how close to perfect the LLM was to matching the SME
						// suppose there are 10 candidates and 3 alignments, which matched the first, second, and fifth candidates (indices 0, 1, and 4)
						// for the first alignment, ideally it would have been first out of 10 and it was, so its rank score is (100 - ((0 - 0) / 10 * 100)) = 100
						// for the second alignment, ideally it would have beeen first out of the remaining 9 and it was, so its rank score is (100 - ((1 - 1) / 9 * 100)) = 100
						// for the third alignment, ideally it would have been first out of the remaining 8; its rank score is (100 - ((4 - 2) / 8 * 100)) = 75
						indices.sort((a,b)=>a-b)
						let denom = resource.candidates.length
						let highest_rank_score = 100 - (indices[0] / denom * 100)
						// console.log(highest_rank_score, denom, indices)
						
						let sum__mean_rank_scores = 0
						for (let i = 0; i < indices.length; ++i) {
							// console.log(i, indices[i], (100 - ((indices[i] - i) / denom * 100)))
							sum__mean_rank_scores += (100 - ((indices[i] - i) / denom * 100))
							--denom
						}

						stats.resources.push(resource)
						stats.n_candidates.push(resource.candidates.length)
						stats.highest_ranks.push(indices[0] + 1)
						stats.highest_rank_scores.push(highest_rank_score)
						stats.mean_rank_scores.push(Math.round(sum__mean_rank_scores / n_matches * 10) / 10)
						stats.highest_comp_scores.push(highest_comp_score)
						stats.mean_comp_scores.push(Math.round(sum_comp_scores / n_matches))
					}
				}
			}
			console.log(stats)
			let sum_highest_ranks = 0, sum_highest_rank_scores = 0, sum_highest_comp_scores = 0, sum_mean_rank_scores = 0, sum_comp_scores = 0, sum_n_candidates = 0
			for (let i = 0; i < stats.resources.length; ++i) {
				// sum_highest_rank_scores += (100 - (stats.highest_rank_scores[i] / stats.n_candidates[i] * 100))
				// sum_mean_rank_scores += (100 - (stats.mean_rank_scores[i] / stats.n_candidates[i] * 100))

				sum_highest_ranks += stats.highest_ranks[i]
				sum_highest_rank_scores += stats.highest_rank_scores[i]
				sum_mean_rank_scores += stats.mean_rank_scores[i]

				sum_highest_comp_scores += stats.highest_comp_scores[i]
				sum_comp_scores += stats.mean_comp_scores[i]
				
				sum_n_candidates += stats.n_candidates[i]
			}
			let mean_highest_rank = (sum_highest_ranks / stats.resources.length).toFixed(1)
			let mean_highest_rank_score = (sum_highest_rank_scores / stats.resources.length).toFixed(1)
			let mean_rank_score = (sum_mean_rank_scores / stats.resources.length).toFixed(1)

			let mean_highest_comp_score = Math.round(sum_highest_comp_scores / stats.resources.length)
			let mean_comp_score = Math.round(sum_comp_scores / stats.resources.length)
			
			let mean_n_candidates = Math.round(sum_n_candidates / stats.resources.length)

			console.log(`n_resources	high_rank	high_rank_score	mean_rank_score	high_cs	mean_cs	mean_candidates\n${stats.resources.length}	${mean_highest_rank}	${mean_highest_rank_score}	${mean_rank_score}	${mean_highest_comp_score}	${mean_comp_score}	${mean_n_candidates}`)
		},

	}
}
</script>

<style lang="scss">
.k-resource-set-wrapper {
	transition: all 0.25s;
	margin:0 auto;

	.k-framework-list-wrapper-inner {
		max-height: calc(100vh - 142px);
	}
}

.k-resource-set-viewing-resource {
	// width is now set in vuetify.breakpoint.width watcher
	// max-width:900px;
	// margin:0 calc(50vw - 392px);
}

.k-resource-set-viewing-resource-embedded-showing {
	margin:0;
}

.k-resource-set-table {
	max-width: 1200px;
	margin:0 auto;
	th {
		line-height:16px;
	}
}

.k-resource-set-table-min {
	width:100px;
	white-space:nowrap;
	overflow: hidden;
}

.k-resource-set-table-min-min {
	width:40px;
	padding:0!important;
	white-space:nowrap;
}

.k-resource-set-alignment-settings {
	.v-label {
		white-space:nowrap;
		font-size:14px;
	}
}

.k-resource-factor-tooltip-outer {
	display:flex;
	flex-direction:column;
}
.k-resource-factor-tooltip {
	flex:0 0 100%;
	display:flex;
	font-size:12px;
	line-height:17px;
	.k-resource-factor-description {
		flex:1 0 auto;
		margin-right:8px;
	}
	.k-resource-factor-total {
		width:24px;
		margin-left:4px;
	}
}

.k-resource-set-tfr-paused {
	opacity:0.5;
}

.k-resource-set-last-viewed-resource {
	td {
		background-color:$v-yellow-lighten-4;
	}
}
</style>