<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" fullscreen scrollable persistent content-class="k-sbse-dialog">
		<v-card>
			<v-card-title style="border-bottom:1px solid #999; flex-wrap:nowrap; justify-content: space-between;" :class="card_title_class"
				:style="card_title_style">
				<div style="display: flex; flex: 1; min-width: 0;">
					<div
						style="flex: 1; min-width:0; max-width: 400px; padding:12px 6px 0px 6px; background-color:#fff; border-radius:6px; margin:-8px 8px;">
						<v-select light v-model="archive_fn" background-color="#fff" :items="archive_select_options_from"
							label="Compare from..." dense hide-details >
							 
							<template v-slot:item="{ item, index }">
								<span
									style="font-size:16px; text-overflow: ellipsis" v-html="item.text">
								</span>
							</template>
							<template
								v-slot:selection="{ item, index }">
								<div style="white-space:nowrap;width:100%;overflow:hidden; text-overflow: ellipsis;" v-html="item.text"></div>
							</template></v-select>
					</div>
					<div
						style="display: flex; flex:1; min-width:0; max-width: 400px; flex-direction:row; padding:12px 6px 0px 6px; background-color:#fff; border-radius:6px; margin:-8px 8px;">
						<v-select light v-model="archive_fn_to" background-color="#fff" :items="archive_select_options_to"
							label="Compare to..." dense hide-details :disabled="!archive_fn">
							<template v-slot:item="{ item, index }">
								<v-tooltip bottom v-if="item.isDisabled">
									<template v-slot:activator="{ on, attrs }">
										<span
											v-html="item.text"
											v-bind="attrs"
															v-on="on"
											style="opacity: 0.5; font-size:16px;"
											@click.stop.prevent="() => {}"
										></span>
									</template>
									<span>Cannot compare newer archives to older ones.</span>
								</v-tooltip>
								<span
									v-else
									style="font-size:16px;"
									v-html="item.text"
								></span>
							</template>
							<template
								v-slot:selection="{ item, index }">
								<div style="white-space:nowrap;width:100%;overflow:hidden; text-overflow: ellipsis;" v-html="item.text"></div>
							</template>
						</v-select>
						<div style="display: flex; align-items: center; padding-left: 4px; flex-shrink: 0">
							<v-btn x-small color="primary" style="white-space: nowrap;" @click="archive_fn_to = 'current'" :disabled="archive_fn_to == 'current'">
							  Current State
							</v-btn>
						  </div>
					</div>
				</div>
				<nobr v-show="!printing">
					<!-- <v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" icon color="orange" class="mr-2" @click="stamp_archive"><v-icon>fas fa-stamp</v-icon></v-btn></template><div class="text-center">“Stamp” an archive<br> of the current framework state</div></v-tooltip> -->
					<v-tooltip bottom><template v-slot:activator="{ on }"><v-btn v-on="on" fab x-small color="#fff"
								class="mr-2" @click="copy_archive_link"><v-icon>fas fa-link</v-icon></v-btn></template>
						<div class="text-center">Copy link to this<br>Side-by-side comparison view</div>
					</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{ on }"><v-btn v-on="on" fab x-small color="#fff"
								class="mr-2" @click="print"><v-icon>fas
									fa-print</v-icon></v-btn></template>Print</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{ on }"><v-btn v-on="on" v-if="user_can_edit" fab
								x-small :color="editing_enabled ? 'primary' : '#fff'" class="mr-2"
								@click="toggle_edit"><v-icon>fas fa-edit</v-icon></v-btn></template>Toggle
						editing on/off</v-tooltip>
					<v-btn color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas
							fa-check</v-icon>Done  </v-btn>
				</nobr>
			</v-card-title>
			<v-card-text v-if="!archive_framework_record" class="pt-5 text-center" :class="card_text_class"
				:style="card_text_style" style="font-size:20px"><i>To get started, choose an archive from the menu
					above.</i></v-card-text>
			<v-card-text v-if="archive_framework_record" class="pt-3" :class="card_text_class" :style="card_text_style"
				style="font-size:16px; line-height:20px; background: white !important;">
				<div class="d-flex align-center mb-3">
					<div style="font-size:20px; line-height:26px;"><b class="grey--text text--darken-3"
							style="font-size:16px">TOP-LEVEL ELEMENT:</b> <span class="k-sbse-head-node-element"
							v-html="format_item_statement(head_node)"></span></div>
					<v-spacer />

					<div class="mx-5" style="white-space:nowrap">
						<b style="color:#333">Unchanged Items:</b>
						<v-btn-toggle dense active-class="k-toggle-btn-active-class" color="primary"
							class="k-toggle-btn" v-model="show_unchanged_items" mandatory>
							<v-btn x-small light :value="false">Hide</v-btn>
							<v-btn x-small light :value="true">Show</v-btn>
						</v-btn-toggle>
					</div>
					<v-menu left><template v-slot:activator="{ on }"><v-btn v-on="on" x-small color="#fff"
								class="mr-3">Show changes to…</v-btn></template>
						<v-list dense>
							<v-list-item v-for="(mitem, key) in viewer.track_changes_field_descriptions" :key="key"
								@click.stop="viewer.set_track_changes_fields(key)"
								v-show="key == 'fullStatement' || key == 'humanCodingScheme' || key == 'loose_comparisons'"><v-list-item-title><v-icon
										small color="#555" class="mr-2" style="margin-top:-2px"
										v-visible="track_changes_fields[key]">fas fa-check-circle</v-icon><span
										v-html="mitem"></span></v-list-item-title></v-list-item>
						</v-list>
					</v-menu>

					<v-btn v-if="!all_collapsed && !printing" x-small text color="#333" class="ml-2 elevation-0"
						@click="collapse_all"><v-icon small class="mr-2">fas fa-angles-up</v-icon>Collapse All</v-btn>
					<v-btn v-if="!all_expanded && !printing" x-small text color="#333" class="ml-2 elevation-0"
						@click="expand_all"><v-icon small class="mr-2">fas fa-angles-down</v-icon>Expand All</v-btn>
				</div>

				<div class="k-sbse-items-wrapper" :class="items_wrapper_class">
					<div class="k-sbse-item-outer k-sbse-item-outer-header">
						<div class="k-sbse-item-old-statement pl-3 py-2" style="border:0;">Original Code/Statement</div>
						<div class="k-sbse-item-new-statement pl-3 py-2" style="border:0;">Updated Code/Statement</div>
						<div class="k-sbse-item-comments k-sbse-item-comments-collapsed py-1 text-center"
							style="border-top:0;border-bottom:0;border-right:0" @click="comment_column_collapsed = false">
							<v-icon color="#fff" small>fas fa-comment</v-icon></div>
						<div class="k-sbse-item-comments k-sbse-item-comments-not-collapsed pl-3 py-2" style="border:0"
							@click="comment_column_collapsed = true"><v-icon color="#fff" class="mr-2"
								style="margin-top:-4px" small>fas fa-comment</v-icon>Comments</div>
					</div>
					<div v-for="(row, index) in editor_rows" :key="row.id"
						v-if="row.showing && (show_unchanged_items || row.item_or_descendant_changed)"><v-hover
							v-slot:default="{ hover }">
							<div style="position:relative">
								<div v-if="item_type(row)" class="k-sbse-item-type"
									v-show="hover || items_being_edited[row.id]" :class="item_type_class(row, hover)">
									{{ item_type(row) }}</div>
								<div class="k-sbse-item-outer" :class="item_outer_class(row, hover)">
									<div class="k-sbse-item-old-statement">
										<div>
											<div class="k-case-tree-item-track-changes-btn"
												@click.stop="toggle_row_open(row)">
												<v-icon class="k-sbse-change-icon">fas
													{{ tracked_change_icon(row) }}</v-icon>
											</div>
										</div>
										<div class="k-sbse-item-statement-text" style="flex:1 1 auto"
											:style="level_indent_style(row)">
											<v-btn v-if="row.archived_node && row.archived_node.children.length > 0"
												class="k-case-tree-item-node-btn" :style="node_btn_style(row)" x-small
												icon color="grey darken-2" @click.stop="toggle_row_open(row)"><v-icon
													small>fas
													fa-chevron-circle-right</v-icon></v-btn>
											<v-btn v-if="row.archived_node && row.archived_node.children.length == 0"
												class="k-case-tree-item-node-btn" small icon color="grey darken-2"
												@click.stop="toggle_row_open(row)"><v-icon small>fas
													fa-dot-circle</v-icon></v-btn>
											<div class="k-sbse-code-plus-statement">
												<b v-if="row.archived_node && row.archived_node.cfitem.humanCodingScheme != null"
													class="k-case-tree-item-statement-hcs mr-1 grey--text text--darken-2"
													v-html="human_coding_scheme(row, 'old')"></b>
												<div class="k-case-tree-item-statement k-case-tree-markdown"
													v-html="old_statement(row)"></div>
											</div>
										</div>
										<v-btn v-visible="hover && item_updated(row)" class="k-sbse-cell-btn" small icon
											color="primary" style="margin-right:-4px;margin-top:-4px;"
											@click.stop="show_history(row)"><b style="font-size:18px">Δ</b></v-btn>
									</div>

									<div class="k-sbse-item-new-statement" :id="`k-sbse-new-statement-${row.id}`">
										<div class="k-sbse-item-statement-text" :style="level_indent_style(row)">
											<v-btn v-if="row.node && row.node.children.length > 0"
												class="k-case-tree-item-node-btn" :style="node_btn_style(row)" x-small
												icon color="grey darken-2" @click.stop="toggle_row_open(row)"><v-icon
													small>fas
													fa-chevron-circle-right</v-icon></v-btn>
											<v-btn v-if="row.node && row.node.children.length == 0"
												class="k-case-tree-item-node-btn" small icon color="grey darken-2"
												@click.stop="toggle_row_open(row)"><v-icon small>fas
													fa-dot-circle</v-icon></v-btn>
											<div class="k-sbse-code-plus-statement">
												<b v-if="row.node && row.node.cfitem.humanCodingScheme != null"
													class="k-case-tree-item-statement-hcs mr-1 grey--text text--darken-2"
													v-html="human_coding_scheme(row, 'new')"></b>
												<div class="k-case-tree-item-statement k-case-tree-markdown"
													v-html="marked_full_statement(row, 'new')"></div>
											</div>
											<v-spacer />
											<!-- <v-menu nudge-top="-26" :attach="`#k-sbse-new-statement-${row.id}`"><template v-slot:activator="{on}"><v-btn v-on="on" v-visible="(hover&&user_can_edit)||item_updated(row)||row.descendants_changed>0" v-show="!items_being_edited[row.id]" small icon :color="hover?'primary':'#666'" style="margin-right:-4px;margin-top:-4px;"><v-icon small>fas fa-edit</v-icon></v-btn></template>
										<v-list dense>
											<v-list-item @click="toggle_item_editor(row)"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit item</v-list-item-title></v-list-item>
											<v-list-item @click=""><v-list-item-icon><b style="color:#666">Δ</b></v-list-item-icon><v-list-item-title>Show change history</v-list-item-title></v-list-item>
										</v-list>
									</v-menu> -->
											<v-btn v-visible="(hover && user_can_edit) || item_updated(row)"
												class="k-sbse-cell-btn" v-show="!items_being_edited[row.id]" small icon
												color="primary" style="margin-right:-4px;margin-top:-4px;"
												@click.stop="toggle_item_editor(row)"><v-icon small>fas
													fa-edit</v-icon></v-btn>
										</div>
										<v-expand-transition>
											<div v-show="items_being_edited[row.id]" class="k-sbse-editor-outer">
												<div class="d-flex k-case-ie-line mb-1 align-stretch">
													<div style="flex:0 0 120px; display:flex; flex-direction:column;"
														class="mr-1">
														<v-text-field background-color="#fff" outlined dense
															hide-details v-model="edited_hcs[row.id]"
															placeholder=""></v-text-field>
														<!-- <v-spacer/>
											<v-btn small text class="k-tight-btn mb-2" color="red darken-4" @click="toggle_item_editor(row)"><v-icon small class="mr-2">fas fa-xmark</v-icon>Cancel Edits</v-btn>
											<v-btn small text color="green darken-4" @click="save_changes(row)"><v-icon small class="mr-2">fas fa-save</v-icon>Save Edits</v-btn> -->
													</div>
													<div style="flex:1 0 auto">
														<v-textarea @keydown.tab.prevent="tabber($event)"
															background-color="#fff" ref="fs_input" outlined dense
															hide-details v-model="edited_fs[row.id]" placeholder=""
															rows="2" auto-grow></v-textarea>
													</div>
												</div>
												<div class="d-flex pt-1">
													<v-btn x-small text color="red darken-4" class="k-tight-btn"
														@click="toggle_item_editor(row)"><v-icon small class="mr-2">fas
															fa-xmark</v-icon>Cancel Edits</v-btn>
													<v-spacer />
													<v-menu nudge-top="-22"><template v-slot:activator="{ on }"><v-btn
																v-on="on" x-small text color="primary"
																class="k-tight-btn"><v-icon small class="mr-2">fas
																	fa-ellipsis-v</v-icon>More
																Options</v-btn></template>
														<v-list dense>
															<v-list-item
																@click="add_sibling(row)"><v-list-item-icon><v-icon
																		small>fas
																		fa-down-long</v-icon></v-list-item-icon><v-list-item-title>Add
																	new sibling item</v-list-item-title></v-list-item>
															<v-list-item
																@click="add_child(row)"><v-list-item-icon><v-icon small
																		style="transform:rotate(90deg);">fas
																		fa-turn-up</v-icon></v-list-item-icon><v-list-item-title>Add
																	new child item</v-list-item-title></v-list-item>
															<v-list-item
																@click="split_item(row)"><v-list-item-icon><v-icon small
																		style="transform:rotate(45deg);">fas
																		fa-up-right-and-down-left-from-center</v-icon></v-list-item-icon><v-list-item-title>Split
																	item</v-list-item-title></v-list-item>
															<v-list-item
																@click="move_items(row)"><v-list-item-icon><v-icon
																		small>fas
																		fa-arrows-alt</v-icon></v-list-item-icon><v-list-item-title>Move
																	items</v-list-item-title></v-list-item>
															<!-- <v-list-item @click="show_full_editor(row)"><v-list-item-icon><v-icon>fas fa-ellipsis-h</v-icon></v-list-item-icon><v-list-item-title>Show full item editor</v-list-item-title></v-list-item> -->
															<v-list-item
																@click="delete_item(row)"><v-list-item-icon><v-icon
																		small color="red darken-3">fas
																		fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title
																	class="red--text text--darken-3">Delete
																	item</v-list-item-title></v-list-item>
														</v-list>
													</v-menu>

													<v-spacer />
													<v-btn x-small text color="green darken-4" class="k-tight-btn"
														@click="save_changes(row)"><v-icon small class="mr-2">fas
															fa-save</v-icon>Save Edits</v-btn>
												</div>
											</div>
										</v-expand-transition>
									</div>

									<div class="k-sbse-item-comments">
										<div v-if="!comments_showing[row.id]" style="width:100%" class="d-flex">
											<div style="width:calc(100% - 24px); overflow:auto;">
												<SideBySideComments :viewer="viewer" :item="row.node"
													:framework_record="framework_record" :item_identifier="row.id" />
											</div>
											<div v-visible="(hover || item_has_comment(row))"
												style="width:24px; text-align:right;">
												<v-btn small icon color="primary" class="k-sbse-comment-icon-btn"
													@click.stop="toggle_comments_showing(row)"><v-icon
														small>{{ item_has_comment(row) ? 'fas' : 'far' }}
														fa-comment</v-icon></v-btn>
											</div>
										</div>
										<div v-if="comments_showing[row.id]" style="flex:1 0 100%">
											<TileComments :viewer="viewer" :item="row.node"
												:framework_record="framework_record" :item_identifier="row.id"
												@hide="toggle_comments_showing(row)" />
										</div>
									</div>

								</div>
							</div>
						</v-hover></div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SideBySideComments from '../comments/SideBySideComments'
import TileComments from '../comments/TileComments'

export default {
	components: { SideBySideComments, TileComments },
	props: {
		viewer: { required: true },
		head_identifier: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() {
		return {
			dialog_open: true,
			highlight_text_diffs: true,
			items_being_edited: {},
			edited_hcs: {},
			edited_fs: {},

			comment_column_collapsed: true,
			comments_showing: {},
			edited_comment: {},

			printing: false,
			fs_diffs: {},
			archive_fn_to: 'current',
			archive_record: null
		}
	},
	computed: {
		...mapState([]),
		...mapGetters(['comments_hash']),
		editing_enabled() { return this.viewer.editing_enabled },
		user_can_edit() { return this.viewer.user_can_edit },
		head_node() {
			if (this.head_identifier == this.framework_record?.lsdoc_identifier) return this.framework_record?.cfo.cftree
			else return this.framework_record?.cfo.cfitems[this.head_identifier].tree_nodes[0]
		},
		framework_record() {
			if (!this.archive_record) {
				return this.viewer.framework_record
			} else {
				return this.archive_record
			}
		},
		framework_archives() { return this.$store.state.framework_archives[this.viewer.framework_record.lsdoc_identifier] },
		archive_select_options_from() {
			if (!this.framework_archives) return []
			let arr = []
			for (let archive of this.framework_archives) {
				// only show stamped archives
				if (archive.archive_note && archive.archive_note != '--' && archive.archive_note != 'mirror auto-update') {
					// make starred items bold, and remove [[]] from the ends of descriptions
					let text = archive.archive_note.replace(/\[\[.*\]\]/, '')
					if (text[0] == '*') text = `<b>${text.substr(1)}</b>`
					text = `${date.format(U.convert_to_local_date(archive.timestamp, 0), 'M/D/YYYY hh:mm A')} – ${text}`
					arr.push({ value: archive.archive_fn, text: text, timestamp: archive.timestamp })
				}
			}
			arr.sort((a, b) => { return -1 * U.natural_sort(a.timestamp, b.timestamp) })
			
			return arr
		},
		archive_select_options_to() {
			if (!this.framework_archives) return []
			let arr = []
			for (let archive of this.framework_archives) {
				// only show stamped archives
				if (archive.archive_note && archive.archive_note != '--' && archive.archive_note != 'mirror auto-update') {
					let text = archive.archive_note.replace(/\[\[.*\]\]/, '')
					if (text[0] == '*') text = `<b>${text.substr(1)}</b>`
					text = `${date.format(U.convert_to_local_date(archive.timestamp, 0), 'M/D/YYYY hh:mm A')} – ${text}`
					const selectedArchive = this.framework_archives.find(archive => archive.archive_fn == this.archive_fn)
					let isDisabled = selectedArchive ? archive.timestamp <= selectedArchive.timestamp : false
					arr.push({ value: archive.archive_fn, text: text, timestamp: archive.timestamp, isDisabled: isDisabled })
				}
			}
			arr.sort((a, b) => { return -1 * U.natural_sort(a.timestamp, b.timestamp) })
			// Add the current state of the archive
			const currentState = {
				value: 'current',
				text: 'Current State',
				timestamp: new Date().getTime()
			}
			arr.unshift(currentState)
			return arr
		},
		archive_fn: {
			get() { return this.viewer.track_changes_fn },
			set(val) {
				let archive = this.framework_archives.find(x => x.archive_fn == val)
				this.viewer.track_changes(archive.archive_fn, archive.timestamp, archive.archive_note)
			}
		},
		archive_framework_record() {
			if (this.viewer.track_changes_framework_record?.cfo) {
				return this.viewer.track_changes_framework_record
			}
			return null
		},
		open_nodes() { return this.framework_record?.open_nodes },
		all_collapsed() {
			if (!this.head_node) return false
			// if all the top-level children are collapsed, everything is collapsed for our purposes here
			for (let child of this.head_node.children) {
				// if any child is *open*, everything *isn't* collapsed
				if (this.open_nodes[child.tree_key]) return false
			}
			// if we get to here, everything is closed/collapsed
			return true
		},
		all_expanded() {
			let check_all_expanded = (node) => {
				if (node.children.length == 0) return null	// if no children, it can't be expanded or collapsed; return null so we don't "count" it either way
				// the node has children; if the node isn't open, everything can't be expanded, so return false
				if (!this.open_nodes[node.tree_key]) return false
				for (let child of node.children) {
					// if any child returns false, we return false
					if (check_all_expanded(child) === false) return false
				}
				// if we get to here, everything's expanded, so return true
				return true
			}
			return check_all_expanded(this.head_node)
		},
		editor_rows() {
			console.log('calculating editor_rows')
			let arr = []
			let bump_parent_descendants_changed = (parent_row) => {
				if (parent_row == null) return
				parent_row.descendants_changed += 1
				bump_parent_descendants_changed(parent_row.parent_row)
			}

			let parse_node = (parent_row, parent_node, level) => {
				for (let child_node of parent_node.children) {
					let o = {}
					o.id = child_node.cfitem.identifier
					o.level = level
					o.descendants_changed = 0
					o.parent_row = parent_row
					o.node = child_node
					o.archived_node = this.archive_framework_record?.cfo.cfitems[child_node.cfitem.identifier]?.tree_nodes[0] ?? null
					o.item_changed = this.item_updated(o)

					// if this is a top-level item, always showing
					if (empty(parent_row)) o.showing = true
					// else if parent_node is open and the parent_row is showing, this is showing
					else if (this.open_nodes[parent_node.tree_key] && parent_row.showing) o.showing = true
					else o.showing = false

					arr.push(o)

					if (o.item_changed) bump_parent_descendants_changed(parent_row)

					parse_node(o, child_node, level + 1)
				}

				// look for children of this parent_node that were in the archive and are now deleted
				let archive_parent_node = this.archive_framework_record?.cfo.cfitems[parent_node.cfitem.identifier]?.tree_nodes[0] ?? null
				if (archive_parent_node) for (let child_node of archive_parent_node.children) {
					// if this child is also in the current framework, don't include. note that if the item was moved, its current node should show up elsewhere (though it might not be a descendant of the current head_node)
					if (this.framework_record?.cfo.cfitems[child_node.cfitem.identifier]) continue

					let o = {}
					o.id = child_node.cfitem.identifier
					o.level = level
					o.descendants_changed = 0
					o.parent_row = parent_row
					o.node = null	// if we're here, it means there is no corresponding current node
					o.archived_node = child_node
					o.item_changed = this.item_updated(o)
					if (empty(parent_row)) o.showing = true
					else if (this.open_nodes[parent_node.tree_key] && parent_row.showing) o.showing = true
					else o.showing = false
					arr.push(o)
					if (o.item_changed) bump_parent_descendants_changed(parent_row)
					// don't process children of deleted nodes (?)
				}
			}
			parse_node(null, this.head_node, 0)

			// now, after checking all descendants, set item_or_descendant_changed for every item
			for (let o of arr) {
				o.item_or_descendant_changed = (o.item_changed || o.descendants_changed > 0)
			}

			return arr
		},
		card_title_class() {
			const framework_color = U.framework_color(this.framework_record)
			if (!isNaN(framework_color)) 'k-framework-color-' + framework_color + '-med'
			return ''
		},
		card_title_style() {
			return U.framework_color_object(this.framework_record)
		},
		card_text_class() {
			const framework_color = U.framework_color(this.framework_record)
			if (!isNaN(framework_color)) 'k-framework-color-' + framework_color + '-lighten-4'
			return ''
		},
		card_text_style() {
			return ''
		},
		any_comment_showing() {
			for (let id in this.comments_showing) {
				if (this.comments_showing[id]) {
					return true
				}
			}
			return false
		},
		items_wrapper_class() {
			let s = ''
			if (this.printing) s += ' k-sbse-items-wrapper-printing'
			if (this.any_comment_showing) {
				s += ' k-sbse-items-wrapper-comments-showing'
			} else if (this.comment_column_collapsed) {
				s += ' k-sbse-items-wrapper-comments-collapsed'
			}
			return s
		},
		track_changes_fields() { return this.$store.state.lst.track_changes_fields },
		show_unchanged_items: {
			get() { return this.$store.state.lst.side_by_side_show_unchanged_items },
			set(val) { this.$store.commit('lst_set', ['side_by_side_show_unchanged_items', val]) }
		},
	},
	watch: {
		'track_changes_fields.loose_comparisons'() { this.fs_diffs = {} },
		archive_fn() {
			// Get selected archive timestamp
			const selectedFromArchive = this.framework_archives.find(
				archive => archive.archive_fn === this.archive_fn
			)

			// If comparing to another archive (not current)
			if (this.archive_fn_to !== 'current') {
				const selectedToArchive = this.framework_archives.find(
					archive => archive.archive_fn === this.archive_fn_to
				)

				// Reset to current if "from" timestamp is newer than or equal to "to" timestamp 
				if (selectedFromArchive.timestamp >= selectedToArchive.timestamp) {
					this.archive_fn_to = 'current'
				}
			}

			// Reset diffs cache since we're changing comparison
			this.fs_diffs = {}
		},
		archive_fn_to() {
			if (this.archive_fn_to === 'current') {
				this.archive_record = null
			} else if (typeof this.archive_fn_to === 'string') {

				let filepath = sr('framework_archives/$1', this.archive_fn_to)
				let to_archive_json;
				U.get_json_file(filepath, (archive_json)=>{
					const case_json = archive_json

					const archive_record = U.create_framework_record(
						case_json.CFDocument.identifier,
						case_json,
						this.viewer.framework_record.ss_framework_data,
						true
					)

					U.build_cfo(this.viewer.$worker, archive_record.json).then((cfo) => {
						if (!cfo) {
							console.error('Failed to build CFO')
							return
						}
						archive_record.cfo = cfo
						U.loading_stop()
						this.archive_record = archive_record
					}).catch(err => {
						console.error('Error building CFO:', err)
						U.loading_stop()
					})
				})
			} else {
				this.archive_record = null
			}
		},
	},
	created() {
		// load framework archives if necessary
		if (!this.framework_archives) {
			this.$store.dispatch('load_framework_archive_list', this.framework_record?.lsdoc_identifier)
		}
	},
	mounted() {
		// console.log(this.editor_rows)
		// To use this tool, we need to set track_changes_fields so fullStatement and highlight_text_diffs are true and no others are on, except loose_comparisons and humanCodingScheme (which can be either)
		let o = object_copy(this.$store.state.lst.track_changes_fields)
		for (let field in o) {
			if (field == 'fullStatement' || field == 'highlight_text_diffs') o[field] = true	// turn on fullStatement and highlight_text_diffs
			else if (field == 'humanCodingScheme' || field == 'loose_comparisons') { }	// leave these as is
			else o[field] = false
		}
		this.$store.commit('lst_set', ['track_changes_fields', o])

		vapp.side_by_side_editor = this
	},
	methods: {
		toggle_edit() {
			this.viewer.toggle_editing()
		},

		get_fs_diff(row) {
			// calculate fullStatement diffs for this row if we don't already have them
			if (empty(this.fs_diffs[row.id])) {
				// note that we calculate based on marked/preserve_latex; so when we render we can render the latex
				let s = marked(U.preserve_latex(row.node ? row.node.cfitem.fullStatement : ''))
				let archived = marked(U.preserve_latex(row.archived_node ? row.archived_node.cfitem.fullStatement : ''))
				let flags = 'preserve_tags preserve_latex'
				if (this.track_changes_fields.loose_comparisons) flags += ' alphanum_only'
				this.fs_diffs[row.id] = U.diff_string(archived, s, flags)
			}
			return this.fs_diffs[row.id]
		},

		item_updated(row) {
			// if there is no archived_node or node, it's new or deleted, so obviously it's been updated
			if (!row.archived_node || !row.node) return true

			// base on alphanum_only if we're using loose_comparisons
			if (this.track_changes_fields.loose_comparisons) {
				if (U.alphanum_only(marked(row.archived_node.cfitem.fullStatement)) != U.alphanum_only(marked(row.node.cfitem.fullStatement))) return true
				if (this.track_changes_fields.humanCodingScheme && U.alphanum_only(row.node.cfitem.humanCodingScheme) != U.alphanum_only(row.archived_node.cfitem.humanCodingScheme)) return true
			} else {
				if (row.archived_node.cfitem.fullStatement != row.node.cfitem.fullStatement) return true
				if (this.track_changes_fields.humanCodingScheme && row.archived_node.cfitem.humanCodingScheme != row.node.cfitem.humanCodingScheme) return true
			}
			return false
			// return (row.archived_node.cfitem.fullStatement != row.node.cfitem.fullStatement || row.archived_node.cfitem.humanCodingScheme != row.node.cfitem.humanCodingScheme)
		},

		tracked_change_icon(row) {
			// note that we don't use U.tracked_change_icon here because we go on the basis of a smaller set of possible keys in the side-by-side editor

			// item is in the current framework, but not in the archived framework, so it was added
			if (!row.archived_node) return 'fa-circle-plus'
			// item is in the archived framework, but not in the new framework, so it was deleted
			if (!row.node) return 'fa-circle-xmark'

			// changes in fullstatement, or humanCodingScheme, if we're using it here
			if (this.track_changes_fields.loose_comparisons) {
				if (U.alphanum_only(marked(row.archived_node.cfitem.fullStatement)) != U.alphanum_only(marked(row.node.cfitem.fullStatement))) return 'fa-circle-half-stroke'
				if (this.track_changes_fields.humanCodingScheme && U.alphanum_only(row.archived_node.cfitem.humanCodingScheme) != U.alphanum_only(row.node.cfitem.humanCodingScheme)) return 'fa-circle-half-stroke'
			} else {
				if (row.archived_node.cfitem.fullStatement != row.node.cfitem.fullStatement) return 'fa-circle-half-stroke'
				if (this.track_changes_fields.humanCodingScheme && row.archived_node.cfitem.humanCodingScheme != row.node.cfitem.humanCodingScheme) return 'fa-circle-half-stroke'
			}
			if (row.descendants_changed > 0) return 'fa-circle-play'
			return 'fa-circle'
		},

		// many display fns below adapted from CASEItemTile
		human_coding_scheme(row, old_or_new) {
			if (this.track_changes_fields.humanCodingScheme && this.highlight_text_diffs) {
				let s = row.node?.cfitem.humanCodingScheme ?? ''
				let archived = row.archived_node?.cfitem.humanCodingScheme ?? ''
				let diff = U.diff_string(archived, s)
				return (old_or_new == 'new') ? diff.n : diff.o

			} else {
				let cfitem = (old_or_new == 'new') ? row.node.cfitem : row.archived_node?.cfitem
				return cfitem ? cfitem.humanCodingScheme : ''
			}
		},

		marked_full_statement(row, old_or_new) {
			if (old_or_new == 'new' && !row.node) return `<div class=""><i><b class="red--text"><i class="fas fa-xmark mr-2"></i>Item DELETED</b></i></div>`

			if (this.highlight_text_diffs) {
				// note that we preserve latex in get_fs_diffs
				let diff = this.get_fs_diff(row)
				diff = (old_or_new == 'new') ? diff.n : diff.o

				return U.render_latex(U.preserve_latex_reverse(diff))

			} else {
				let cfitem = (old_or_new == 'new') ? row.node.cfitem : row.archived_node.cfitem
				return cfitem ? U.marked_latex(cfitem.fullStatement) : ''
			}
		},

		old_statement(row) {
			// if item wasn't in old framework, say so
			if (empty(row.archived_node)) {
				return `<div class="" style="width:100%"><i><b class="purple--text"><i class="fas fa-plus mr-2"></i>Item ADDED</b></i></div>`
			}
			let descendants = ''
			if (row.descendants_changed > 0) descendants = ` <b style="color:#333; font-size:0.85em; white-space:nowrap;">(${row.descendants_changed} ${U.ps('descendant', row.descendants_changed)} updated)</b>`

			if (!this.item_updated(row)) {
				return `unchanged${descendants}`
			} else {
				return this.marked_full_statement(row, 'old') + descendants
			}
		},

		item_outer_class(row, hover) {
			let s = ''
			if (hover) s += ' k-sbse-item-outer-hovered'
			if (!this.item_updated(row)) s += ' k-sbse-item-outer-unchanged'
			else s += ' k-sbse-item-outer-changed'
			s += ' k-sbse-item-outer-' + this.tracked_change_icon(row)
			if (this.items_being_edited[row.id]) s += ' k-sbse-item-outer-being-edited'
			return s
		},

		node_btn_style(row) {
			let node = row.node ?? row.archived_node
			if (this.open_nodes[node.tree_key]) return 'transform:rotate(90deg)'
		},

		item_type_class(row, hover) {
			let s = ''
			if (hover) s += ' k-sbse-item-type-hovered'
			s += ' k-sbse-item-type-' + this.tracked_change_icon(row)
			if (this.items_being_edited[row.id]) s += ' k-sbse-item-type-being-edited'
			return s
		},

		level_indent_style(row) {
			let s = `padding-left:${row.level * 20}px;`
			return s
		},

		item_type(row) {
			let node = row.node ?? row.archived_node
			let s = U.item_type_string(node.cfitem)
			if (!s) return ''
			// if (!s) return 'no item type'
			return s
		},

		format_item_statement(o) {
			if (o.cfitem) o = o.cfitem

			// cfdocument
			if (o.title) return o.title

			// cfitem
			let s = o.fullStatement
			if (o.humanCodingScheme) s = `<b>${o.humanCodingScheme}</b> ${s}`
			return s
		},

		expand_all() {
			for (let row of this.editor_rows) {
				this.toggle_row_open(row, true)
			}
		},

		collapse_all() {
			for (let row of this.editor_rows) {
				this.toggle_row_open(row, false)
			}
		},

		toggle_row_open(row, now_open) {
			let node = row.node ?? row.archived_node
			if (typeof (now_open) != 'boolean') now_open = !this.open_nodes[node.tree_key]
			if (now_open) this.$store.commit('set', [this.open_nodes, node.tree_key + '', now_open])
			else this.$store.commit('set', [this.open_nodes, node.tree_key + '', '*DELETE_FROM_STORE*'])
		},

		tabber(event) { U.tabber(event) },

		// comments
		item_has_comment(row) {
			return !empty(this.comments_hash[this.framework_record?.lsdoc_identifier]) && !empty(this.comments_hash[this.framework_record?.lsdoc_identifier][row.id])
		},

		toggle_comments_showing(row, val) {
			if (typeof (val) != 'boolean') val = !this.comments_showing[row.id]
			this.$set(this.comments_showing, row.id, val)
		},

		// show history
		show_history(row) {
			this.viewer.more_info(row.node, 'show_history')
		},

		// item editor
		toggle_item_editor(row, val) {
			if (!this.editing_enabled) {
				if (!this.user_can_edit) this.$alert('You do not have editing rights to this framework.')
				else this.$alert('Click the <i class="fas fa-edit"></i> button at the top of the window to enable editing mode.')
				return
			}
			if (!row.node) {
				this.$alert('Since this item has been deleted, you cannot edit it.')
				return
			}
			if (typeof (val) != 'boolean') val = !this.items_being_edited[row.id]
			this.$set(this.items_being_edited, row.id, val)
			if (val) {
				this.$set(this.edited_hcs, row.id, row.node.cfitem.humanCodingScheme)
				this.$set(this.edited_fs, row.id, row.node.cfitem.fullStatement)
			}
		},

		save_changes(row) {
			let CFItem = new window.CFItem(row.node.cfitem)
			console.log(CFItem)

			// trim inputted values; put values that can have markdown through U.remove_line_breaks_from_text_field
			CFItem.humanCodingScheme = $.trim(this.edited_hcs[row.id])
			CFItem.fullStatement = U.remove_line_breaks_from_text_field(this.edited_fs[row.id])

			// if nothing changed, just close editor
			if (CFItem.humanCodingScheme == row.node.cfitem.humanCodingScheme && CFItem.fullStatement == row.node.cfitem.fullStatement) {
				this.$inform('Nothing changed')
				this.toggle_item_editor(row, false)
				return
			}

			if (empty(CFItem.fullStatement)) {
				this.$alert('All CASE items must specify a full statement.')
				return
			}

			let json_to_save
			if (row.is_new_item) {
				// if this is a new item, save the "normal" json
				json_to_save = CFItem.to_json()
			} else {
				// for an update, use to_json_for_update to set values of unset optional parameters to '*CLEAR*'
				json_to_save = CFItem.to_json_for_update()
			}

			let data = {
				update_item_types: true,	// this will trigger the save_framework_data dispatch fn to deal with CFItemTypes
				lsdoc_identifier: this.framework_record?.lsdoc_identifier,
				CFItems: [
					json_to_save
				],
				CFAssociations: [],
			}

			// if this is a newly-added item...
			if (row.is_new_item) {
				// create an 'isChildOf' association for the item, and add to data
				// note that new nodes will always be carefully created with the to-be-used sequenceNumber as node.sequence
				let child_association = U.create_association(row.node.cfitem, CFItem, row.node.sequence, this.framework_record?.json.CFDocument)
				data.CFAssociations.push(child_association.to_json())

			} else {
				// if the humancodingscheme or fullstatement changed, update CFAssociations.nodeURI.title values if necessary
				let index = U.find_cfassociation_index(this.framework_record?.json.CFAssociations, row.node.cfitem.identifier, row.node.parent_node.cfitem.identifier)
				if (index == -1) { this.$alert('Error'); return; }	// this shouldn't happen
				let cfa = this.framework_record?.json.CFAssociations[index]
				let new_cfa_title = U.generate_cfassociation_node_uri_title(CFItem)
				if (cfa.originNodeURI.title != new_cfa_title) {
					this.$store.commit('set', [cfa.originNodeURI, 'title', new_cfa_title])
					data.CFAssociations.push(new CFAssociation(cfa).to_json())
				}
			}

			data.show_spinner = false	// don't show spinner while saving an individual item
			console.log(data)
			this.$store.dispatch('save_framework_data', data).then(() => {
				// create or update the CFItem(s) in the framework json
				for (let cfi of data.CFItems) {
					let index = this.framework_record?.json.CFItems.findIndex(x => x.identifier == cfi.identifier)

					// use server date/time for all timestamps
					if (cfi.lastChangeDateTime == '*NOW*') {
						cfi.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}

					// convert '*CLEAR*' properties back to empty values before transferring in to client memory
					for (let key in cfi) if (cfi[key] === '*CLEAR*') delete cfi[key]
					if (cfi.extensions) for (let key in cfi.extensions) if (cfi.extensions[key] === '*CLEAR*') delete cfi.extensions[key]

					// then get a new clean json object
					let clean_json = new window.CFItem(cfi).to_json()

					if (index == -1) {
						this.$store.commit('set', [this.framework_record?.json.CFItems, 'PUSH', clean_json])
					} else {
						this.$store.commit('set', [this.framework_record?.json.CFItems, 'SPLICE', index, clean_json])
					}

					// transfer updates to the cfo cfitem
					let cfitem = this.framework_record?.cfo.cfitems[cfi.identifier]
					this.$store.commit('set', [cfitem, 'humanCodingScheme', cfi.humanCodingScheme])
					this.$store.commit('set', [cfitem, 'fullStatement', cfi.fullStatement])
				}

				// we may have saved CFAssociations
				for (let assoc of data.CFAssociations) {
					// use server date/time for all timestamps
					if (assoc.lastChangeDateTime == '*NOW*') {
						assoc.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}

					// add to or update in the CFAssociations
					let index = this.framework_record?.json.CFAssociations.findIndex(x => x.identifier == assoc.identifier)
					if (index == -1) {
						this.$store.commit('set', [this.framework_record?.json.CFAssociations, 'PUSH', assoc])
					} else {
						this.$store.commit('set', [this.framework_record?.json.CFAssociations, 'SPLICE', index, assoc])
					}
				}

				// if we inserted a new item, remove the new_item_parent_node attribute from original_node
				if (row.is_new_item) {
					this.$store.commit('set', [row.node, 'new_item_parent_node', '*DELETE_FROM_STORE*'])
				}

				// call update_frameworks_with_associations in case association data needs to be updated
				this.viewer.update_frameworks_with_associations()

				// close the editor now
				this.toggle_item_editor(row, false)
			})
		},

		print() {
			this.printing = true
			$('.v-dialog__content').css('position', 'static')
			$('.v-dialog').css('position', 'static')
			$('.v-application--wrap').hide()
			$('.v-card__title').hide()
			$('.v-card__text').css('overflow-y', 'visible')
			let window_title = document.title
			document.title = this.framework_record?.json.CFDocument.title + ': ' + $('.k-sbse-head-node-element').text()

			// $('.v-card').css('box-shadow', '')
			// $('.v-dialog').css('box-shadow', '')
			setTimeout(x => {
				window.print()
				setTimeout(x => {
					this.printing = false
					$('.v-dialog__content').css('position', 'fixed')
					$('.v-dialog').css('position', 'fixed')
					$('.v-application--wrap').show()
					$('.v-card__title').show()
					$('.v-card__text').css('overflow-y', 'auto')
					document.title = window_title
				}, 10)
			}, 10)
		},

		add_sibling(row) {
			this.viewer.edit_item(row.node, 'add_sibling')
			setTimeout(x => {
				// get new item
				let new_item = this.viewer.current_editor.CFItem
				// set fullStatement to "new item" so we can save it, but set edited_fs to empty string
				new_item.fullStatement = 'NEW ITEM'
				this.$set(this.items_being_edited, new_item.identifier, true)
				this.$set(this.edited_hcs, new_item.identifier, new_item.humanCodingScheme)
				this.$set(this.edited_fs, new_item.identifier, '')
				this.viewer.current_editor.save_changes('and_done')
			}, 100)
		},

		add_child(row) {
			this.viewer.edit_item(row.node, 'add_child')
			setTimeout(x => {
				// get new item
				let new_item = this.viewer.current_editor.CFItem
				// set fullStatement to "new item" so we can save it, but set edited_fs to empty string
				new_item.fullStatement = 'NEW ITEM'
				this.$set(this.items_being_edited, new_item.identifier, true)
				this.$set(this.edited_hcs, new_item.identifier, new_item.humanCodingScheme)
				this.$set(this.edited_fs, new_item.identifier, '')
				this.viewer.current_editor.save_changes('and_done')
			}, 100)
		},

		split_item(row) {
			// same as add_sibling, except that we start with the text of the original item
			this.viewer.edit_item(row.node, 'add_sibling')
			setTimeout(x => {
				// get new item
				let new_item = this.viewer.current_editor.CFItem
				new_item.fullStatement = row.node.cfitem.fullStatement
				this.$set(this.items_being_edited, new_item.identifier, true)
				this.$set(this.edited_hcs, new_item.identifier, new_item.humanCodingScheme)
				this.$set(this.edited_fs, new_item.identifier, new_item.fullStatement)
				this.viewer.current_editor.save_changes('and_done')
			}, 100)
		},

		delete_item(row) {
			// conveniently, the editor's delete_item fn will warn the user about deleting children, make them confirm, and take care of the delete
			this.viewer.edit_item(row.node, 'delete_item')
		},

		move_items() {
			// send these params into toggle_move_mode; when the user finishes moving, they will be sent back here
			this.viewer.toggle_move_mode('reopen_side_by_side', this.head_identifier)
			this.$emit('dialog_cancel')
		},

		// not currently implemented; you don't seem to be able to interact with the editor interface on top of the dialog
		show_full_editor(row, arg) {
			if (empty(arg)) arg = ''
			this.viewer.edit_item(row.node, arg)
			setTimeout(x => {
				$('.k-editor-wrapper-outer').css('z-index', 100000)
			}, 10)
		},

		copy_archive_link() { this.viewer.copy_archive_link() },

		stamp_archive() {
			this.$alert('This button is not yet functional.')
		},

	}
}
</script>

<style lang="scss">
.k-sbse-dialog {
	border-radius: 0 !important;
	box-shadow: none !important;

	.v-card {
		border-radius: 0 !important;
	}

	// this would force background colors to appear in the whole dialog; instead (for now at least) we apply these in  k-sbse-item-old-statement and k-sbse-item-new-statement only
	// -webkit-print-color-adjust:exact !important;
	// print-color-adjust:exact !important;
}

.k-sbse-items-wrapper {
	// border-bottom:1px solid #ddd;
	background-color: #fff;
	border: 3px solid #333;
	border-radius: 5px;
}

.k-sbse-item-outer {
	display: flex;
	// margin:4px 0;
	// border-top:1px solid #ddd;
	// border-bottom:1px solid #ddd;
	// background-color:#fff;
	// margin-top:-2px;
	position: relative;
}

.k-sbse-item-outer-header {
	font-weight: bold;
	background-color: #333;
	border-bottom-color: #333;
	color: #fff;
	font-size: 14px;

	.k-sbse-item-old-statement {
		border-width: 0;
	}

	.k-sbse-item-new-statement {
		border-top-width: 0;
		border-bottom-width: 0;
	}

	.k-sbse-item-comments {
		border-top-width: 0;
		border-bottom-width: 0;
		border-right-width: 0
	}
}

.k-sbse-item-outer-hovered {
	background-color: #eee;
	z-index: 2;

	.k-sbse-item-old-statement {
		border-color: #999;
		border-radius: 6px 0 0 6px;
	}

	.k-sbse-item-new-statement {
		border-top-color: #999;
		border-bottom-color: #999;
	}

	.k-sbse-item-comments {
		border-top-color: #999;
		border-bottom-color: #999;
		border-right-color: #999;
		border-radius: 0 6px 6px 0;
	}
}

.k-sbse-item-outer-being-edited {
	background-color: #f8f8f8;
	z-index: 2;

	.k-sbse-item-old-statement {
		border-color: #555;
		border-radius: 6px 0 0 6px;
	}

	.k-sbse-item-new-statement {
		border-top-color: #555;
		border-bottom-color: #555;
	}

	.k-sbse-item-comments {
		border-top-color: #555;
		border-bottom-color: #555;
		border-right-color: #555;
		border-radius: 0 6px 6px 0;
	}
}

// .k-sbse-item-outer-being-edited { 
// 	// background-color:#eee; 
// 	border-color:#555;
// 	.k-sbse-item-old-statement { border-color:#555; border-radius:8px 0 0 8px; }
// 	// .k-sbse-item-new-statement { border-color:#555; }
// 	.k-sbse-item-comments { border-right-color:#555; }
// }

.k-sbse-code-plus-statement {
	// background-color:rgba(255,255,255,0.7);
	// border-radius:8px;
	// padding:2px;
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	// margin-top:2px;
}

.k-sbse-item-outer-unchanged {
	.k-sbse-item-old-statement {

		.k-case-tree-item-node-btn,
		.k-case-tree-item-node-btn,
		.k-sbse-code-plus-statement {
			opacity: 0.7;
		}
	}
}

// .k-sbse-item-outer-fa-circle { background-color:$v-green-lighten-5; }

// .k-sbse-item-outer-fa-circle { background-color:#fff; }
// .k-sbse-item-outer-fa-circle-play { background-color:$v-amber-lighten-5; }
// .k-sbse-item-outer-fa-circle-half-stroke { background-color:$v-light-blue-lighten-5; }
// .k-sbse-item-outer-fa-circle-plus { background-color:$v-purple-lighten-5; }
// .k-sbse-item-outer-fa-circle-xmark { background-color:$v-red-lighten-5; }

// .k-sbse-item-outer-fa-circle.k-sbse-item-outer-hovered, .k-sbse-item-outer-fa-circle.k-sbse-item-outer-being-edited { background-color:$v-green-lighten-4; }
// .k-sbse-item-outer-fa-circle-play.k-sbse-item-outer-hovered, .k-sbse-item-outer-fa-circle-play.k-sbse-item-outer-being-edited { background-color:$v-amber-lighten-4; }
// .k-sbse-item-outer-fa-circle-half-stroke.k-sbse-item-outer-hovered, .k-sbse-item-outer-fa-circle-half-stroke.k-sbse-item-outer-being-edited { background-color:$v-light-blue-lighten-4; }
// .k-sbse-item-outer-fa-circle-plus.k-sbse-item-outer-hovered, .k-sbse-item-outer-fa-circle-plus.k-sbse-item-outer-being-edited { background-color:$v-purple-lighten-4; }
// .k-sbse-item-outer-fa-circle-xmark.k-sbse-item-outer-hovered, .k-sbse-item-outer-fa-circle-xmark.k-sbse-item-outer-being-edited { background-color:$v-red-lighten-4; }

// .k-sbse-item-outer-being-edited, .k-sbse-item-outer-being-edited.k-sbse-item-outer-hovered {
// 	border-color:#555;
// 	.k-sbse-item-old-statement { border-color:#555; }
// 	.k-sbse-item-new-statement { border-color:#555; }
// 	.k-sbse-item-comments { border-color:#555; }
// }

.k-sbse-item-type {
	position: absolute;
	left: 100px;
	// right:calc(60% + 4px);
	top: -24px;
	font-weight: bold;
	color: #000;
	border-top: 1px solid #999;
	border-left: 1px solid #999;
	border-right: 1px solid #999;
	border-radius: 5px 5px 0 0;
	padding: 3px 6px;
	z-index: 3;
	font-size: 14px;
	line-height: 18px;
	background-color: #fff;
}

// .k-sbse-item-type-fa-circle { background-color:$v-green-lighten-5; }

// .k-sbse-item-type-fa-circle { background-color:#fff; }
// .k-sbse-item-type-fa-circle-play { background-color:$v-amber-lighten-5; }
// .k-sbse-item-type-fa-circle-half-stroke { background-color:$v-light-blue-lighten-5; }
// .k-sbse-item-type-fa-circle-plus { background-color:$v-purple-lighten-5; }
// .k-sbse-item-type-fa-circle-xmark { background-color:$v-red-lighten-5; }

.k-sbse-item-type-hovered {
	background-color: #eee;
}

.k-sbse-item-type-being-edited {
	background-color: #f8f8f8;
}

.k-sbse-item-type-being-edited {
	border-color: #555;
}

.k-sbse-item-old-statement {
	flex: 0 0 auto;
	width: 41%;
	max-width: 572px;
	display: flex;
	padding: 6px 4px 4px 2px;
	border-left: 1px solid transparent;
	border-top: 1px solid #eee;
	border-bottom: 1px solid transparent;

	-webkit-print-color-adjust: exact !important;
	print-color-adjust: exact !important;
}

.k-sbse-change-icon {
	font-size: 20px !important;
	margin-top: -3px;
	margin-right: 2px;
	margin-left: 8px;
	cursor: pointer;
}

.k-sbse-item-new-statement {
	flex: 0 0 auto;
	width: 40%;
	max-width: 560px;
	border-left: 1px solid #eee;
	border-top: 1px solid #eee;
	border-bottom: 1px solid transparent;
	// padding:4px;
	padding: 6px 4px 0 4px;

	-webkit-print-color-adjust: exact !important;
	print-color-adjust: exact !important;
}

.k-sbse-item-statement-text {
	display: flex;
	align-items: flex-start;
}

.k-sbse-editor-outer {
	// background-color:#fff;
	border-radius: 8px;
	margin-top: 12px;
	padding: 8px;
}

.k-sbse-item-comments {
	border-left: 1px solid #eee;
	border-right: 1px solid transparent;
	border-top: 1px solid #eee;
	border-bottom: 1px solid transparent;
	flex: 1 0 auto;
	width: 20px; // this has to have some value, for the inner div's 100% to take effect; but the actual width will be calculated by the flex rule
	padding: 4px;
	display: flex;
	// overflow:auto;

	.k-tile-comments-btn-not-visible-in-side-by-side {
		display: none;
	}

	.k-tile-comments-done-btn {
		display: block;
	}

}

.k-sbse-comment-icon-btn {
	margin: -4px -4px 0 0;
}

.k-sbse-item-comments-not-collapsed {
	display: block;
	cursor: pointer;
}

.k-sbse-item-comments-collapsed {
	display: none;
	cursor: pointer;
}

.k-sbse-items-wrapper-comments-collapsed {
	.k-sbse-item-old-statement {
		flex: 0 1 50%;
		// width:auto;
		max-width: 50%;
	}

	.k-sbse-item-new-statement {
		flex: 0 1 50%;
		// width:auto;
		max-width: 50%;
	}

	.k-sbse-item-comments {
		flex: 0 0 50px;
		width: 50px;
	}

	.k-sbse-item-comments-not-collapsed {
		display: none;
	}

	.k-sbse-item-comments-collapsed {
		display: block;
	}

	.k-sbs-comments {
		display: none;
	}

	.k-sbse-comment-icon-btn {
		margin: -4px 4px -4px -8px;
	}
}

.k-sbse-items-wrapper-comments-showing {
	.k-sbse-item-old-statement {
		flex: 0 1 35%;
		width: auto;
		max-width: auto;
	}

	.k-sbse-item-new-statement {
		flex: 0 1 33%;
		width: auto;
		max-width: auto;
	}

	.k-sbse-item-comments {
		flex: 0 0 500px;
		width: 500px;
	}
}

// @media (min-width: 1600px) {
// 	.k-sbse-item-old-statement {
// 		flex:0 0 35%;
// 		width:auto;
// 		max-width:auto;
// 	}
// 	.k-sbse-item-new-statement {
// 		flex:0 0 33%;
// 		width:auto;
// 		max-width:auto;
// 	}
// 	// .k-sbse-item-comments {
// 	// 	flex:0 0 33%;
// 	// 	width:auto;
// 	// 	max-width:auto;
// 	// }
// }


.k-sbse-item-comment {
	font-size: 14px;
	line-height: 18px;
	padding-left: 4px;

	p {
		margin: 0 0 8px 0;
	}
}

.k-sbse-items-wrapper-printing {
	border: 1px solid #ccc;
	border-radius: 0;

	.k-sbse-item-outer {
		break-inside: avoid-page;

		.k-sbse-cell-btn {
			display: none;
		}
	}

	// font-size:12px; line-height:16px;
	// .k-sbse-item-comment { font-size:12px; line-height:16px; }
	.k-sbse-item-outer-header {
		color: #000 !important;

		.k-sbse-item-old-statement {
			border-width: 2px;
		}

		.k-sbse-item-new-statement {
			border-top-width: 2px;
			border-bottom-width: 2px;
		}

		.k-sbse-item-comments {
			border-top-width: 2px;
			border-bottom-width: 2px;
			border-right-width: 2px
		}
	}
}

@page {
	margin: 0.2in 0;
}
</style>