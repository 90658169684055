<template><div>
    <p><b>Export to Canvas Spreadsheet Format</b></p>
	<p>All frameworks in Satchel can be exported to a format for easy import into Canvas. The Canvas export option allows the user to choose which parts of the selected framework to export for use as <a href="https://canvas.instructure.com/doc/api/file.outcomes_csv.html" target="_blank">Outcomes Import Format</a>
	 in Canvas. See <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-import-outcomes-for-a-course/ta-p/702" target="_blank">this help document</a> for more information. Options to sample the format of the item type selected prior to downloading the CSV file are included. Follow the steps below to export to Canvas spreadsheet format:
	<ul>
		<li>Select the <span v-html="link('kebab_menu', 'kebab menu')"></span> within a framework</li>
		<li>Select EXPORT FRAMEWORK / COPY FRAMEWORK LINK</li>
		<li>Select EXPORT TO CANVAS SPREADSHEET FORMAT….</li>
		<li>Follow the pop-up windows of the wizard.Two pop-up windows will appear. On the left will include the export wizard providing step-by-step instructions. On the right of the window will include an iframe of the framework.</li>
		<li>When importing to Canvas, typically, the lowest-level item type will be the “outcome” and all parent items will be classified as a “group”. According to the Canvas Outcomes Import Format Documentation, “A value of ‘outcome’ indicates this is a learning outcome. A value of ‘group’ indicates this is a learning outcome group”.</li>
		
		<li>Step 1: To start the process, choose which “branches” of the framework you wish to import to Canvas, by checking the boxes in the framework tree shown at right. If you don’t choose any branches and click the “MOVE TO STAGE 2” button, the entire framework will be imported.</li>
		<img srcset="/docimages/export_canvas_spreadsheet_1.png 2x" class="k-help-img">
		<img srcset="/docimages/checked_item.png 1.1x" class="k-help-img float-right">
		<li>Step 2: Next, choose which framework item types should be imported, as well as whether each type should be considered “outcomes” and “groups” in Canvas. Canvas “groups” preserve, at least to some extent, the “tree structure” of the framework as represented in CASE Network 2; see the Canvas help docs linked above.</li>
		<img srcset="/docimages/export_canvas_spreadsheet_2.png 2.2x" class="k-help-img block">
		
	</ul>
	By default, the “lowest-level” item type will be selected as outcomes, and all other types will be selected as groups. But you may wish to change this default. For example, many frameworks include item types such as “Grade Level”, “Strand”, “Cluster”, “Standard”, and “Component”. You might choose to import Grade Levels, Strands, and Clusters as groups, import Standards as outcomes, and not import Components at all.
	<br>
	<br>
	<strong>NOTE:</strong> At least one item type must be designated as “outcome” to proceed.
	
	<ul>
		<li>Step 3: Next, choose from the following options. Each of these settings will be applied to every outcome in the exported file.<img srcset="/docimages/export_canvas_spreadsheet_3.png 2.2x" class="k-help-img block">
			<ul>
				<li>Refer to the <a href="https://canvas.instructure.com/doc/api/file.outcomes_csv.html" target="_blank">Outcomes Import Format Documentation</a> for more information related to each of the options in Step 3.</li>
			</ul>
		</li>
		<li>Select the PREPARE CSV button.</li>
		
		<li>Satchel will process and prepare the CSV. Once complete,  a summary of the csv will appear. Click the DOWNLOAD CSV button if the summary is satisfactory.
			<img srcset="/docimages/export_canvas_spreadsheet_4.png 2.2x" class="k-help-img block">
			<ul>
				<li><strong>Note:</strong> after attempting to import, if a notification from Canvas about items failing to import appears, check what successfully imported and re-format the “outcomes” selections as needed.</li>
			</ul>
		</li>
	</ul>
</p>
       
       </div></template>
       <script>
           import HelpMixin from './HelpMixin'
           export default {
               mixins: [HelpMixin],
           }
       </script>
       
       <style lang="scss">
       </style>