<template>
	<div id="k-rubric-viewer">
		<div class="k-rubric-viewer-grid-container">
			<div class="k-rubric-viewer-box">
				<div v-if="criteria.length">
					<!-- Header row for table -->
					<div class="k-rubric-viewer-header">
						<div class="k-rubric-viewer-header-cell-first grey darken-3 white--text" style="font-size:14px">
							<b class="pl-3">Criteria</b>
						</div>
						<div class="k-rubric-viewer-header-cell" v-for="criterion_level, index in criteria[0].CFRubricCriterionLevels" :key="criterion_level.identifier" style="position: relative;">
							<!-- Interface for deleting/shifting columns -->
							<div v-show="editing_on && editing_level_column(index)" style="position: absolute; top: -50%; left:50%; transform: translate(-50%, -50%); width: fit-content; padding-bottom:6px">
								<v-tooltip top><template v-slot:activator="{on}"><v-icon class="k-rubric-viewer-action-icon" v-on="on" v-show="criterion_level.position -1 !== 0" small @click="shift_column('left', criterion_level.position)">fas fa-chevron-circle-left</v-icon></template>Move Left</v-tooltip>
								<v-tooltip top><template v-slot:activator="{on}"><v-icon class="k-rubric-viewer-action-icon" v-on="on" v-show="criterion_level.position !== criteria[0].CFRubricCriterionLevels.length" small @click="shift_column('right', criterion_level.position)">fas fa-chevron-circle-right</v-icon></template>Move Right</v-tooltip>
								<v-tooltip top><template v-slot:activator="{on}"><v-icon class="k-rubric-viewer-action-icon ml-2" v-on="on" small color="red" @click="remove_column(criterion_level.position)">fas fa-trash-alt</v-icon></template>Delete Column</v-tooltip>
							</div>
							<!-- this is the actual editor text -->
							<div class="k-rubric-viewer-column-header" @click="edit_column(criterion_level)" v-bind="is_last_focused_input(criterion_level, 'column') ? framework_color_bind : ''" :style="editing_on?'cursor:pointer;':'padding-left:0!important'">
								<v-tooltip top><template v-slot:activator="{on}"><v-icon v-show="editing_on && !(last_focused_input && last_focused_input.identifier==criterion_level.identifier)" v-on="on" color="primary" small @click="" style="position:absolute; left:4px; top:5px;">fas fa-edit</v-icon></template>Edit this criterion level (column)</v-tooltip>
								{{criterion_level.quality}} [{{criterion_level.score}}]
							</div>
						</div>
					</div>

					<!-- Each row (criterion) of the table -->
					<div v-for="criterion, index in sorted_criteria" :key="criterion.identifier" class="k-rubric-viewer-row">
						<!-- Interface for deleting/shifting rows -->
						<div style="position: absolute; top: 6px; left:-24px; width: fit-content; display: flex; flex-direction: column; gap:4px">
							<v-tooltip top><template v-slot:activator="{on}"><v-icon v-visible="associated_item(criterion.identifier)" v-on="on" color="primary" small @click="focus_in_criterion_description(criterion)">fas fa-arrows-left-right</v-icon></template>Associated to <span v-html="associated_item_uri_title(criterion.identifier)"></span></v-tooltip>
							<v-tooltip top><template v-slot:activator="{on}"><v-icon v-show="editing_on && !(last_focused_input && last_focused_input.identifier==criterion.identifier)" v-on="on" color="primary" small @click="focus_in_criterion_description(criterion)">fas fa-edit</v-icon></template>Edit this criterion (row)</v-tooltip>
							<v-tooltip right><template v-slot:activator="{on}"><v-icon v-show="editing_on && (last_focused_input && last_focused_input.identifier==criterion.identifier) && index !== 0" class="k-rubric-viewer-action-icon" v-on="on" small @click="shift_row('up', criterion.position)">fas fa-chevron-circle-up</v-icon></template>Move Up</v-tooltip>
							<v-tooltip right><template v-slot:activator="{on}"><v-icon v-show="editing_on && (last_focused_input && last_focused_input.identifier==criterion.identifier) && index+1 !== criteria.length" class="k-rubric-viewer-action-icon" v-on="on" small @click="shift_row('down', criterion.position)">fas fa-chevron-circle-down</v-icon></template>Move Down</v-tooltip>
							<v-tooltip right><template v-slot:activator="{on}"><v-icon v-show="editing_on && (last_focused_input && last_focused_input.identifier==criterion.identifier)" class="k-rubric-viewer-action-icon mt-0" v-on="on" small color="red" @click="remove_row(criterion.position)">fas fa-trash-alt</v-icon></template>Delete Row</v-tooltip>
						</div>

						<!-- criterion description -->
						<div class="k-rubric-viewer-cell k-rubric-viewer-row-description" v-bind="is_last_focused_input(criterion, 'row') ? framework_color_bind : ''" :data-criterion-identifier="criterion.identifier">
							<v-textarea v-if="editing_on" background-color="transparent" auto-grow hide-details outlined dense height="100%" v-model="criterion.description" placeholder="Criterion description" style="border-radius: 0;" @focus="set_last_focused_input(criterion)"/>
							<div v-if="!editing_on" class="k-rubric-viewer-cell-description" @click="set_last_focused_input(criterion)">
								<div v-if="cell_has_metadata(criterion)" style="float:right; margin:0 0 2px 2px; cursor:pointer"><v-icon small color="#777">fas fa-note-sticky</v-icon></div>
								<div v-html="criterion.description"></div>
							</div>
						</div>

						<!-- each criterion level -->
						<div v-bind="is_last_focused_input(criterion_level, 'cell') ? framework_color_bind : ''" :style="last_focused_input==criterion?'background-color:#eee':''" class="k-rubric-viewer-cell" v-for="criterion_level in criterion.CFRubricCriterionLevels" :key="criterion_level.identifier" :data-criterion-level-identifier="criterion_level.identifier">
							<v-textarea v-if="editing_on" background-color="transparent" auto-grow hide-details outlined no-resize dense height="100%" v-model="criterion_level.description" placeholder="Criterion cell description" style="border-radius: 0;height: 100%; border: none !important;" @focus="set_last_focused_input(criterion_level)"/>
							<div v-if="!editing_on" class="k-rubric-viewer-cell-description" @click="set_last_focused_input(criterion_level)">
								<div v-if="cell_has_metadata(criterion_level)" style="float:right; margin:0 0 2px 2px"><v-icon small color="#777">fas fa-note-sticky</v-icon></div>
								<div v-html="criterion_level.description"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- buttons to add rows/columns -->
			<div v-if="editing_on" class="k-rubric-viewer-vertical-bar ml-1">
				<v-icon @click="add_criterion_levels" style="cursor: pointer;">fa fa-plus-circle</v-icon>
			</div>
			<div v-if="editing_on" class="k-rubric-viewer-horizontal-bar mt-1">
				<v-icon @click="add_criterion" style="cursor: pointer;">fa fa-plus-circle</v-icon>
			</div>
		</div>

		<!-- Settings/more info Wrapper -->
		<div v-if="!!last_focused_input" class="pa-2 mt-3 elevation-3" style="border-radius:8px; border:2px solid #333;" v-bind="settings_wrapper_bind">
			<!-- Close btn for settings -->
			<div style="position:absolute; right:4px; top:4px;"><v-btn small icon color="#000" dark @click="set_last_focused_input(null)"><v-icon>fas fa-circle-xmark</v-icon></v-btn></div>

			<!-- Settings for criterion levels (column) -- there's no need to show this unless we're editing -->
			<div v-if="last_focused_input.editing_column" style="display: flex; gap:16px; flex-direction: column;">
				<h4>Criterion Level (column) Metadata</h4>

				<div style="display: flex; gap:16px;">
					<div style="flex:0 0 90px">
						<div class="k-case-ie-line-label">Level Score:</div>
						<v-text-field background-color="#fff" outlined dense hide-details v-model="last_focused_input.score" type="number" />
					</div>
					<div style="flex:1">
						<div class="k-case-ie-line-label">Criterion Level Header (“quality”):</div>
						<v-text-field background-color="#fff" outlined dense hide-details @input="(event) => update_level_qualities(event)" v-model="last_focused_input.quality" />
					</div>
				</div>
			</div>

			<!-- Settings for criterion row -->
			<div v-if="last_focused_input.rubricId" style="display: flex; flex-direction: column;  gap:16px;">
				<h4>Criterion (row) Metadata</h4>

				<div v-if="editing_on && !associated_item(last_focused_input.identifier)">
					<v-btn small class="k-tight-btn" @click="show_aligned_item" v-bind="framework_color_bind_button">
						<v-icon small class="mr-2">fas fa-map</v-icon>Associate Criterion to Item
					</v-btn>
				</div>
				<div v-if="associated_item(last_focused_input.identifier)" class="d-flex align-start">
					<v-icon @click="show_aligned_item" color="#222" label="associated to">fas fa-arrows-left-right</v-icon>
					<span @click="show_aligned_item" class="ml-2" style="cursor:pointer" v-html="associated_item_uri_title(last_focused_input.identifier)"></span>
					<v-btn class="ml-2 elevation-1" x-small fab v-bind="framework_color_bind_button" dark @click="show_aligned_item"><v-icon small>fas fa-map</v-icon></v-btn>
					<v-btn v-if="editing_on" class="ml-2 elevation-1" x-small fab color="red darken-2" dark @click="remove_association"><v-icon small>fas fa-trash-alt</v-icon></v-btn>
				</div>

				<div v-if="editing_on" style="display: flex; gap:16px;">
					<div style="flex:0 0 120px">
						<div class="k-case-ie-line-label">Criterion Weight:</div>
						<v-text-field background-color="#fff" outlined dense hide-details v-model="last_focused_input.weight" type="number"/>
					</div>
					<div style="flex:1">
						<div class="k-case-ie-line-label">Criterion Notes:</div>
						<v-textarea background-color="#fff" outlined dense hide-details rows="1" v-model="last_focused_input.notes" placeholder="" auto-grow clearable/>
					</div>
				</div>
				<div v-if="!editing_on">
					<div><b>Criterion Weight:</b> {{ last_focused_input.weight }}</div>
					<div v-if="last_focused_input.notes" class="mt-2">
						<b>Criterion Notes:</b>
						<div class="mt-1">{{ last_focused_input.notes }}</div>
					</div>
				</div>
			</div>

			<!-- Settings for criterion cells -->
			<div v-if="last_focused_input.rubricCriterionId" style="display: flex; gap:16px; flex-direction: column;">
				<h4>Criterion Cell Metadata</h4>

				<div v-if="editing_on && !associated_item(last_focused_input.identifier)">
					<v-btn small class="k-tight-btn" @click="show_aligned_item" v-bind="framework_color_bind_button">
						<v-icon small class="mr-2">fas fa-map</v-icon>Associate Criterion Cell to Item
					</v-btn>
				</div>
				<div v-if="associated_item(last_focused_input.identifier)" class="d-flex align-start">
					<v-icon @click="show_aligned_item" color="#222" label="associated to">fas fa-arrows-left-right</v-icon>
					<span @click="show_aligned_item" class="ml-2" style="cursor:pointer" v-html="associated_item_uri_title(last_focused_input.identifier)"></span>
					<v-btn class="ml-2 elevation-1" x-small fab v-bind="framework_color_bind_button" dark @click="show_aligned_item"><v-icon small>fas fa-map</v-icon></v-btn>
					<v-btn v-if="editing_on" class="ml-2 elevation-1" x-small fab color="red darken-2" dark @click="remove_association"><v-icon small>fas fa-trash-alt</v-icon></v-btn>
				</div>

				<div v-if="editing_on" style="display: flex; gap:16px;">
					<!-- we'll implement this if we need to -->
					<div v-if="level_scores_vary_by_criterion" style="flex:0 0 90px">
						<div class="k-case-ie-line-label">Level Score:</div>
						<v-text-field background-color="#fff" outlined dense hide-details v-model="last_focused_input.score" type="number" />
					</div>
					<div style="flex:1">
						<div class="k-case-ie-line-label">Criterion Cell Feedback:</div>
						<v-textarea background-color="#fff" outlined dense hide-details rows="1" v-model="last_focused_input.feedback" auto-grow clearable />
					</div>   
					<div style="flex:1">
						<div class="k-case-ie-line-label">Criterion Cell Notes:</div>
						<v-textarea background-color="#fff" outlined dense rows="1" hide-details v-model="last_focused_input.notes" auto-grow clearable />
					</div>
				</div>
				<div v-if="!editing_on">
					<div v-if="last_focused_input.feedback" :class="last_focused_input.notes?'mb-2':''">
						<b>Feedback:</b>
						<div class="mt-1">{{ last_focused_input.feedback }}</div>
					</div>
					<div v-if="last_focused_input.notes">
						<b>Notes:</b>
						<div class="mt-1">{{ last_focused_input.notes }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		criteria: { type: Array, required: true },
		rubric_identifier: { type: String, required: true },
		framework_record: { type: Object, required: true },
		editing_on: { type: Boolean, required: true },
		initial_rubric_component_identifier_showing: { type: String, required:false, default() { return ''}}
	},
	model: {
		prop: 'criteria',
		event: 'update:criteria'
	},
	data() { return {
		last_focused_input: null,
		settings_wrapper_updater: 1,
		// we will implement a checkbox for this if/when we need it; for now, we always assume the level scores are the same for every criterion
		level_scores_vary_by_criterion: false,
	}},
	computed: {
		// reference to the currently-active CASEFrameworkViewer, if there is one (there probably always should be if we're showing a rubric)
		viewer() { return vapp.case_tree_component },

		framework_color_bind() {
			const framework_color = U.framework_color(this.framework_record.lsdoc_identifier)
			if (!isNaN(framework_color)) {
				return { class: `k-framework-color-${framework_color}-lighten-4` }

			} else {
				const framework_color_object = U.framework_color_object(this.framework_record.lsdoc_identifier, 'lighten-5')
				return {
					style: `background-color: ${framework_color_object['background-color']} !important;`
				}
			}
	
		},
		framework_color_bind_button() {
			const framework_color = U.framework_color(this.framework_record.lsdoc_identifier)
			if (!isNaN(framework_color)) {
				return {
					class: `k-framework-color-${framework_color}-dark`,
					style: {'color': 'white'}
				}

			} else {
				const framework_color_object = U.framework_color_object(this.framework_record.lsdoc_identifier)
				return { style: framework_color_object }  
			}
		},

		settings_wrapper_bind() {
			// this allows us to re-position the wrapper when needed
			if (this.settings_wrapper_updater < 0) return

			let o = object_copy(this.framework_color_bind)
			if (!o.style) o.style = ''

			// position the settings wrapper just below the currently-being-edited textarea
			let left, top, width
			let djq = $('#k-rubric-dialog-card')

			// editing a row
			if (this.last_focused_input.rubricId) {
				width = 600
				let jq = $(`[data-criterion-identifier=${this.last_focused_input.identifier}]`)

				// for top, start with the top of the currently-focused textarea and add the height of the textarea
				// then subtract the top of the dialog card, the top of the rubric viewer, and a magic constant
				top = jq.offset().top + jq.height()
				top -= djq.offset().top
				top -= $('#k-rubric-viewer').position().top
				top -= 26

				// for left, start with left of textarea minus a magic constant
				left = jq.offset().left - 80

			// editing a cell
			} else if (this.last_focused_input.rubricCriterionId) {
				width = this.editing_on ? 800 : 600
				let jq = $(`[data-criterion-level-identifier=${this.last_focused_input.identifier}]`)

				// for top, start with the top of the currently-focused textarea and add the height of the textarea
				// then subtract the top of the dialog card, the top of the rubric viewer, and a magic constant
				top = jq.offset().top + jq.height()
				top -= djq.offset().top
				top -= $('#k-rubric-viewer').position().top
				top -= 26

				// for left, start with left of textarea minus a magic constant
				left = jq.offset().left - 80

			// editing a column
			} else {	// if (this.last_focused_input.editing_column) {
				width = 600
				let jq = $(`[data-criterion-level-identifier=${this.last_focused_input.criterion_level.identifier}]`)
				
				// for top, position just below the header: start with the top of the first-row textarea for this column, 
				// then subtract the top of the dialog card, the top of the rubric viewer, and a magic constant
				top = jq.offset().top
				top -= djq.offset().top
				top -= $('#k-rubric-viewer').position().top
				top -= 28

				// for left, start with left of textarea minus a magic constant
				left = jq.offset().left - 90
			}

			// if left would puts us off the right side of the dialog, shift left
			let max_left = djq.width() - width - 40
			if (left > max_left) left = max_left

			o.style += `position:absolute; width:${width}px; top:${top}px; left:${left}px`
			return o
		},

		rubric_entity_association() { return (identifier) => {
			// return the first association for the rubric element identified by identifier
			// associations_hash will hold a hash of any associations that go with the rubric element
			let arr = this.framework_record.cfo.associations_hash[identifier]
			if (!arr || !arr[0]) return null
			if (!U.is_rubric_association(arr[0])) {
				console.warn('something fishy: this should be a rubric association ', arr)
				return null
			}
			return arr[0]
		}},

		associated_item() { return (identifier) => {
			// return the first item associated with the rubric element identified by identifier
			let assoc = this.rubric_entity_association(identifier)
			if (!assoc) return null
			return this.framework_record.json.CFItems.find(x=>x.identifier == assoc.originNodeURI.identifier)
		}},

		associated_item_uri_title() { return (identifier) => {
			// return the "uri_title" (usually humanCodingScheme + text) for the associated item
			if (!identifier) return ''
			let item = this.associated_item(identifier)
			if (!item) return ''
			return U.generate_cfassociation_node_uri_title(item, 80, true)
		}},

		cell_has_metadata() { return (o) => {
			// return true if the entity has metadata (besides the description) to show; first is row; second is column
			if (o.rubricId) return this.associated_item(o.identifier) || o.weight != 1 || o.notes
			else return this.associated_item(o.identifier) || o.feedback || o.notes
		}},

		criterion_level_count() {
			// we assume in Satchel that every criterion for the rubric will have the same number of levels
			return this.criteria[0].CFRubricCriterionLevels.length
		},

		sorted_criteria() {
			// sort the levels of each criterion by the level positions, so that the columns show in the right order
			const sort_by_position = (arr) => arr.sort((a, b) => a.position - b.position);
			this.criteria.forEach(criterion => {
				sort_by_position(criterion.CFRubricCriterionLevels)
			})
			// then sort the criteria themselves!
			return sort_by_position(this.criteria)
		},

		editing_level_column() { return (index) => {
			// if we're editing a column, last_focused_input.criterion_level will have a position value, which we can match against index
			return (this.last_focused_input?.editing_column && this.last_focused_input.criterion_level.position == (index+1))
		}}
	},
	watch: {
		criteria: {
			handler: function (new_value) {
				this.$emit('update:criteria', new_value)
			},
			deep: true
		},
		last_focused_input() {
			if (!this.last_focused_input) return

			// scroll to the row for the specified criterion/level
			let jq
			if (this.last_focused_input.rubricId) jq = $(`[data-criterion-identifier=${this.last_focused_input.identifier}]`)
			else if (this.last_focused_input.rubricCriterionId) jq = $(`[data-criterion-level-identifier=${this.last_focused_input.identifier}]`)
			if (jq) {
				// use vuetify goto
				let container = $('.v-dialog').find('.v-card__text')[0]
				console.warn('here: ', jq, container)
				this.$vuetify.goTo(jq[0], {container:container, offset:0})
			}
		},
	},
	mounted() {
		// this is handy for debugging, and also allows us to check to see if the component disappears (see below)
		vapp.rubric_grid_component = this

		// if initial_rubric_component_identifier_showing is provided, set last_focused_input to that component; by waiting 500ms, the dialog is done appearing before we do this
		setTimeout(x=>{
			if (this.initial_rubric_component_identifier_showing && this.initial_rubric_component_identifier_showing != this.rubric_identifier) {
				for (let rc of this.criteria) {
					if (this.initial_rubric_component_identifier_showing == rc.identifier) this.last_focused_input = rc
					else for (let rcl of rc.CFRubricCriterionLevels) {
						if (this.initial_rubric_component_identifier_showing == rcl.identifier) { this.last_focused_input = rcl; break; }
					}
					if (this.last_focused_input) break
				}
			}
		}, 500)
	},
	methods: {
		is_last_focused_input(input, entity) {
			if (!this.last_focused_input) return false
			if (this.last_focused_input.identifier === input.identifier) {
				// for rows, if the identifiers match always return true
				if (entity == 'row') return true

				// for columns, only return true if last_focused_input.editing_column is true; vice-versa for cells
				if (entity == 'column' && this.last_focused_input.editing_column) return true
				if (entity == 'cell' && !this.last_focused_input.editing_column) return true
			}
			return false
		},

		shift_column(direction, start_position) {
			this.criteria.forEach(criterion => {
				const current_index = criterion.CFRubricCriterionLevels.findIndex(item => item.position === start_position)
				if (current_index === -1) return	// shouldn't happen

				const next_index = (direction === 'right') ? current_index + 1 : current_index - 1

				// Check bounds
				if (next_index < 0 || next_index >= criterion.CFRubricCriterionLevels.length) return

				// Swap positions
				const current_pos = criterion.CFRubricCriterionLevels[current_index].position
				criterion.CFRubricCriterionLevels[current_index].position = criterion.CFRubricCriterionLevels[next_index].position
				criterion.CFRubricCriterionLevels[next_index].position = current_pos
			})

			this.reposition_last_focused_input()
		},

		remove_column(position, confirmed) {
			// make user confirm
			if (!confirmed) {
				this.$confirm({
					title: 'Are you sure?',
					text: 'Are you sure you want to delete this Criterion Level (column)?',
					acceptText: 'Delete',
					acceptColor: 'red darken-2',
				}).then(y => {
					this.remove_column(position, true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			this.criteria.forEach(criterion => {
				const index = criterion.CFRubricCriterionLevels.findIndex(item => item.position === position)

				if (this.last_focused_input) {
					if (this.last_focused_input.position === position && !this.last_focused_input.hasOwnProperty('CFRubricCriterionLevels')) {
						this.last_focused_input = null
					}
				}
				
				if (index !== -1) {
					criterion.CFRubricCriterionLevels.splice(index, 1)
					
					// Update positions for all columns
					let p = 1
					criterion.CFRubricCriterionLevels.forEach(item => item.position = p++)
				}
			})
			// clear last_focused_input
			this.set_last_focused_input(null)
		},

		// shift a row (criterion) up or down
		shift_row(direction, start_position) {
			const current_index = this.criteria.findIndex(item => item.position === start_position)
			if (current_index === -1) return	// shouldn't happen

			const next_index = (direction === 'down') ? current_index + 1 : current_index - 1

			// Check bounds
			if (next_index < 0 || next_index >= this.criteria.length) return this.criteria

			// Swap positions
			const current_pos = this.criteria[current_index].position
			this.criteria[current_index].position = this.criteria[next_index].position
			this.criteria[next_index].position = current_pos

			this.reposition_last_focused_input()
		},

		// delete a row (criterion)
		remove_row(position, confirmed) {
			// make user confirm
			if (!confirmed) {
				this.$confirm({
					title: 'Are you sure?',
					text: 'Are you sure you want to delete this Criterion (row)?',
					acceptText: 'Delete',
					acceptColor: 'red darken-2',
				}).then(y => {
					this.remove_row(position, true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			const index = this.criteria.findIndex(item => item.position === position)

			if (index !== -1) {	// should always be true
				this.criteria.splice(index, 1)
				
				// Update positions for all rows
				let p = 1
				this.sorted_criteria.forEach(c => c.position = p++)
			}
			// clear last_focused_input
			this.set_last_focused_input(null)
		},

		// show the aligned item for a row (CFCriterion) or cell (CFRubricCriterionLevel) (note that we don't allow aligning to columns); if editing is on, allow for editing
		show_aligned_item() {
			let data = {
				framework_identifier: this.framework_record.lsdoc_identifier
			}

			// get identifier of the rubric entity we're aligning to
			let rubric_entity_identifier = this.last_focused_input.identifier
			let existing_association = this.framework_record.cfo.associations_hash[rubric_entity_identifier]
			// if we have an existing association [remember that these are arrays; we look at the first one if there are multiple (for now we don't allow for multiple)]
			if (existing_association) {
				existing_association = existing_association[0]
				// highlight the item (make sure it is fully open)
				data.item_identifier = [existing_association.originNodeURI.identifier]
				// and set selected_items so that the item's checkbox will be selected if editing is on
				data.selected_items = [existing_association.originNodeURI.identifier]
			}

			let show_data = { 
				// fn called when embedded satchel is hidden; here we don't need to do anything special when that happens
				// embed_hide_callback_fn: ()=>{ this.aligning_to_standards = false },

				// fn called continuously while embedded satchel is open; if it returns true, embedded satchel will be closed. 
				// use this to close embedded satchel if the user leaves the rubric editor altogether, or switches to editing a different row or cell
				hide_fn: ()=>{ 
					if ($(vapp.rubric_grid_component?.$el).is(':visible') == false) return true
					if (this.last_focused_input?.identifier != rubric_entity_identifier) return true
					return false
				},
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					// show the chooser if ...
					if (this.editing_on) vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						vapp.$refs.satchel.execute('hide')

						let item_identifier = aligned_item.cfitem.identifier

						// start forming payload we'll send to the server
						let payload =  {
							lsdoc_identifier: this.framework_record.lsdoc_identifier,
							CFAssociations: []
						}

						// if there was an existing association to last_focused_input, we need to remove it
						if (existing_association) {
							payload.CFAssociations.push({identifier: existing_association.identifier, delete: 'yes'})
						}

						// if the user unchecked the existing association, that's all we'll do; otherwise create an association to the selected item
						if (!(existing_association && existing_association.originNodeURI.identifier == item_identifier)) {
							let new_association = new CFAssociation({
								"originNodeURI": {
									"title": U.generate_cfassociation_node_uri_title(aligned_item.cfitem),
									"identifier": item_identifier,
									"uri": aligned_item.cfitem.uri
								},
								// association_type will depend on what we have in last_focused_input
								"associationType": (this.last_focused_input.rubricId) ? 'ext:hasRubricCriterion' : 'ext:hasRubricCriterionLevel',
								"destinationNodeURI": {
									"title": this.last_focused_input.description.substring(0, 20),
									"identifier": rubric_entity_identifier,
									"uri": this.last_focused_input.uri
								}
							})
							new_association.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri
							payload.CFAssociations.push(new_association.to_json())
						}

						this.$store.dispatch('save_framework_data', payload).then(response => {
							// once the save_framework_data service completes successfully, we need to update the framework's data model in the $store
							for (let assoc of payload.CFAssociations) {
								if (assoc.delete == 'yes') {
									// delete from json and associations_hash; if we're deleting something here it is definitionally the existing_association we identified above
									U.remove_from_associations_hash(this.framework_record.cfo, existing_association)
									U.delete_association_from_json(this.framework_record, assoc.identifier)

								} else {
									// set date for the assoc and add it to json and associations_hash
									assoc.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
									this.$store.commit('set', [this.framework_record.json.CFAssociations, 'PUSH', assoc])
									U.update_associations_hash(this.framework_record.cfo, assoc)
									// update associations showing in the tree
									this.viewer.update_association_display({framework_id: this.framework_record.lsdoc_identifier, assoc: assoc, actions: ['add', 'display']})
								}
							}


							// PW: I think this tells the parent component to update what is specified as the model for this component?
							this.$forceUpdate()
						});
					})
				})
			}).catch(()=>console.log('catch of vapp.$refs.satchel.execute(\'show\')'))	// this will execute when the standards are hidden
		},

		remove_association() {
			// NOTE: associations_hash is composed of arrays, but for now we're making the simplifying assumption that only one association is allowed for each rubric entity
			let assoc = this.rubric_entity_association(this.last_focused_input.identifier)
			if (!assoc) return	// shouldn't happen

			// delete_associations will take care of deleting from json and associations_hash
			this.$store.dispatch('delete_associations', {framework_record: this.framework_record, associations_to_delete: [assoc]}).then(x=>{
				// update associations showing in the tree
				this.viewer.update_association_display({framework_id: this.framework_record.lsdoc_identifier, actions: ['remove']})
				this.$forceUpdate()	// PW: not sure if this is needed here, but shouldn't hurt anything
			})
		},

		set_last_focused_input(o) {
			// note that if o is null, the settings panel will be hidden
			// if editing is not on, this is a cell, and the cell has nothing to be shown, don't set last_focused_input
			if (o && !this.editing_on) {
				if (!this.cell_has_metadata(o)) {
					this.last_focused_input = null
					return
				}
			}

			this.last_focused_input = o
		},

		// force the settings wrapper to move to where it should now be
		reposition_last_focused_input() {
			this.$nextTick(x=>++this.settings_wrapper_updater)
		},

		// set focus in the description textarea for the given criterion
		focus_in_criterion_description(criterion) {
			$(`[data-criterion-identifier=${criterion.identifier}]`).find('textarea').focus()
		},
		
		// add a new row (criterion)
		add_criterion() {
			let rc = new CFRubricCriterion()
			rc.rubricId = this.rubric_identifier
			rc.weight = this.criteria[this.criteria.length-1].weight	// copy weight from previous entry
			rc.position = this.criteria[this.criteria.length-1].position + 1
			rc.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri

			// copy the quality, position, and scores from the first criterion
			for (let rcl0 of this.criteria[0].CFRubricCriterionLevels) {
				let rcl = new CFRubricCriterionLevel({
					quality: rcl0.quality,
					position: rcl0.position,
					score: rcl0.score,
					rubricCriterionId: rc.identifier
				})
				rcl.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri
				rc.CFRubricCriterionLevels.push(rcl)
			}

			// now add this new guy to this.criteria, using to_json
			this.criteria.push(rc.to_json())

			// start editing the column
			this.$nextTick(x=>{
				this.last_focused_input = this.criteria[this.criteria.length-1]
				this.focus_in_criterion_description(this.criteria[this.criteria.length-1])
			})
		},

		// add a new column -- adding a CFRubricCriterionLevel to every CFRubricCriterion
		add_criterion_levels() {
			const new_position = this.criterion_level_count + 1
			this.criteria.forEach(criterion => {
				const cl = new CFRubricCriterionLevel({
					quality: 'Header',
					score: new_position,
					position: new_position,
					rubricCriterionId: criterion.identifier,
				})
				cl.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri, and sets lastChangeDateTime to *NOW*, which will be converted to the proper case time string when saved
				criterion.CFRubricCriterionLevels.push(cl)
			})

			// start editing the column
			this.$nextTick(x=>this.edit_column(this.criteria[0].CFRubricCriterionLevels[new_position-1]))
		},

		update_level_qualities(event) {
			// this is called by the input event handler of the text field where the level header ("quality") is set; last_focused_input will include the position we need to update
			// update all criterions' levels for this position to the new value
			this.criteria.forEach(criterion => {
				criterion.CFRubricCriterionLevels.forEach(level => {
					if (level.position === this.last_focused_input.criterion_level.position) {
						// event will be the entered text
						level.quality = event
					}
				})
			})
		},

		// this is called when the user clicks on a column header
		edit_column(criterion_level) {
			// don't set last_focused_input if editing is not on; there's nothing we need to show in the editor interface in view-only mode
			if (!this.editing_on) return

			this.last_focused_input = {
				editing_column: true,
				identifier: criterion_level.identifier,
				score: criterion_level.score,
				quality: criterion_level.quality,
				criterion_level: criterion_level,
			}
		},
	},
}
</script>

<style lang="scss">

#k-rubric-viewer {
	position:relative;
	margin-top: 16px;
	padding-bottom:200px;
	font-size:16px;
	line-height:21px;

	.dialog-title {
		width: 100%;       

		.k-editor-title {
			width: 100%;
			font-size: 16px;
			font-weight: 600;
		}
	}

	.k-rubric-viewer-action-icon {
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}

	.k-rubric-viewer-box {
		grid-column: 1; /* Places in first column */
		grid-row: 1; /* Places in first row */

		textarea {
			height: 100% !important;
			overflow: auto;
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}

		textarea::-webkit-scrollbar {
			display: none;
		}
	}

	.k-rubric-viewer-header {
		height: 28px;
		display: flex;
		border:1px solid #000;
		margin-right:-1px;
		margin-bottom:-1px;
	}

	.k-rubric-viewer-header-cell {
		flex: 1;
		text-align: center;
		/* overflow: hidden; */
	}

	.k-rubric-viewer-header-cell-first {
		flex:0 0 160px;
		text-align:left;
	}

	.k-rubric-viewer-row {
		min-height: 80px;
		display: flex;
		position: relative;
	}

	.k-rubric-viewer-cell {
		min-height: 80px;
		flex: 1;
		background-color: white;
		margin-right:-1px;
		margin-bottom:-1px;
	}

	.k-rubric-viewer-row-description {
		flex:0 0 162px;
		background-color: #ddd;
		font-weight: bold;
	}

	.k-rubric-viewer-cell-description {
		font-size:13px;
		line-height:17px;
		border:1px solid rgb(118, 118, 118);
		padding:4px 8px;
		height:100%;
	}

	// PW: keep k-rubric-viewer-cell-description and the textareas in synch
	.v-textarea {
		.v-input__slot {
			padding-left:6px;
			height: 100% !important;
		}
		
		.v-input__control {
			height: 100% !important;
		}

		textarea {
			font-size:13px;
			line-height:17px;
			margin-top:4px;
		}
	}

	.k-rubric-viewer-grid-container {
		display: grid;
		width: calc(100% - 16px);
		margin-left: 16px;
		grid-template-columns: 1fr 32px; /* Main area takes remaining width, right bar is 20px */
		grid-template-rows: auto 32px; /* Main row fits content, bottom bar is 20px */
		gap: 0; /* No gaps between grid items */
	}

	.k-rubric-viewer-vertical-bar {
		grid-column: 2; /* Places in second column */
		grid-row: 1; /* Places in first row */
		width: 32px;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}

	.k-rubric-viewer-horizontal-bar {
		grid-column: 1 / -1; /* Spans full width (both columns) */
		grid-row: 2; /* Places in second row */
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}

	.k-rubric-viewer-column-header {
		height: 28px;
		padding-top:2px;
		padding-left:24px;
		position:relative;
		font-weight:bold;
		text-align: center;
		font-size: 12px;
		border-left: 1px solid #000;
		background-color:#ddd;
	}
}

</style>