<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Printing/exporting options</h3>

	<p><span v-html="app_name"/> includes a number of options for exporting data for a framework.</p>
	<ul class="k-no-clear">
		<li>Use the <span v-html="link('print_pdf', 'Print (or save as PDF)')"></span> option to print, save the framework as a pdf, or to review the standards in expanded format</li>
		<li>All frameworks in Satchel can be exported to a format for easy import into Canvas using the <span v-html="link('export_canvas_spreadsheet', 'Export to Canvas Spreadsheet')"></span> option.</li>
		<li>Frameworks are available for download into a spreadsheet using the <span v-html="link('export_spreadsheet', 'Export to a Spreadsheet')"></span> option.</li>
		<li>CASE consumers can retrieve the CASE-formatted JSONs by using the <span v-html="link('copy_case_api', 'Copy CASE Package API link')"></span> which retrieves the framework via the standard CASE API.</li>
		<li>Those familiar with the <span v-html="app_name"/> <span v-html="link('batch_add_from_text', 'batch import tool')"></span> will find exporting the framework using the <span v-html="link('satchel_text_format', 'Satchel text format')"></span> helpful in making batch edits.</li>
	</ul>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	