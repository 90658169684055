// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		suggestions_updated_showing: false,
		candidates_to_show_params: {
			min_n: 3,
			max_n: 8,
			threshold: 400,
			max_in_memory: 100,
		},
		ed_level_tolerance: 1,

		debug: false,
	}},
	computed: {
		...mapState(['grades', 'alt_grades', 'calculated_ai_simscores']),
		...mapGetters([]),

		resource_alignment_validation: {
			get() { return this.$store.state.lst.resource_alignment_validation },
			set(val) { this.$store.commit('lst_set', ['resource_alignment_validation', val]) }
		},
		comp_factors: {
			get() {
				let s = this.$store.state.lst.align_comp_factors
				if (s) return JSON.parse(s)
				else return {
					education_level: true,

					title: true,
					description: true,
					text: true,

					item: true,
					parent: true,

					existing_alignments: true,
					sme_assocs: true,
					ai_assocs: true,
				}
			},
			set(val) {
				this.$store.commit('lst_set', ['align_comp_factors', JSON.stringify(this.comp_factors)])
			},
		},
		// weights for calculating overall sim scores
		comp_factor_weights() {
			// note: see below for how we weight based on education_level mismatches 
			return {
				item: 250,
				parent: 50,
				existing_alignment_sme_assocs: 800,
				existing_alignment_ai_assocs: 500,
			}
		},
		comp_factor_total_weight() {
			let x = 0
			if (this.comp_factors.item) x += this.comp_factor_weights.item
			if (this.comp_factors.parent) x += this.comp_factor_weights.parent
			if (this.comp_factors.sme_assocs) x += this.comp_factor_weights.existing_alignment_sme_assocs
			if (this.comp_factors.ai_assocs) x += this.comp_factor_weights.existing_alignment_ai_assocs
			return x
		},
		scaled_comp_factor_weights() {
			return {
				item: Math.round(this.comp_factor_weights.item / this.comp_factor_total_weight * 100),
				parent: Math.round(this.comp_factor_weights.parent / this.comp_factor_total_weight * 100),
				existing_alignment_sme_assocs: Math.round(this.comp_factor_weights.existing_alignment_sme_assocs / this.comp_factor_total_weight * 100),
				existing_alignment_ai_assocs: Math.round(this.comp_factor_weights.existing_alignment_ai_assocs / this.comp_factor_total_weight * 100),
			}
		},
		
		// TODO: expand this and make it configurable...
		limiters: {
			get() { 
				let o = this.$store.state.lst.align_limiters[this.tba_framework_identifier] ?? {}
				if (empty(o.item_types)) o.item_types = []
				if (empty(o.lowest_ancestor)) o.lowest_ancestor = null
				if (empty(o.include_branches)) o.include_branches = []
				if (empty(o.exclude_branches)) o.exclude_branches = []
				return o
			},
			set(val) { this.$store.commit('lst_set_hash', ['align_limiters', this.tba_framework_identifier, val]) }
		},		

		base_framework_identifier: {
			get() { return this.$store.state.lst.align_base_framework_identifier[this.tba_framework_identifier] ?? '' },
			set(val) { this.$store.commit('lst_set_hash', ['align_base_framework_identifier', this.tba_framework_identifier, val]) }
		},
		base_framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.base_framework_identifier) },

		suggestion_factors_string() { return JSON.stringify([this.comp_factors, this.comp_factor_weights, this.limiters]) },

		currently_aligning: {
			get() { return this.$store.state.lst.resource_set_currently_aligning },
			set(val) { this.$store.commit('lst_set', ['resource_set_currently_aligning', val]) }
		},
		// 'to-be-aligned' framework_identifier
		tba_framework_identifier: {
			get() { return this.$store.state.lst.resource_set_tba_framework_identifier_hash[this.resource_set_id] },
			set(val) { this.$store.commit('lst_set_hash', ['resource_set_tba_framework_identifier_hash', this.resource_set_id, val]) }
		},
		tba_framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.tba_framework_identifier) },
		tba_framework_record_descriptor() {
			if (!this.tba_framework_record) return ''
			let s = this.tba_framework_record.json.CFDocument.title
			if (this.tba_framework_record.ss_framework_data.category) {
				let category_data = U.parse_framework_category(this.tba_framework_record.ss_framework_data.category)
				s += ` [${category_data.title}]`
			}
			return s
		},
		tba_available_item_types() { return this.tba_framework_record?.cfo?.item_types },
		tba_item_type_counts() {
			if (!this.tba_framework_record?.cfo) return 0
			let o = {'[no item type]':0}
			for (let identifier in this.tba_framework_record.cfo.cfitems) {
				let item_type = U.item_type_string(this.tba_framework_record.cfo.cfitems[identifier]) || '[no item type]'
				if (empty(o[item_type])) o[item_type] = 0
				++o[item_type]
			}
			return o
		},
	},
	watch: {
		tba_framework_record: {immediate: true, handler(val) {
			// load tba framework if not already loaded
			if (this.tba_framework_identifier && this.tba_framework_record && !this.tba_framework_record.framework_json_loaded && !this.tba_framework_record.framework_json_loading) {
				this.load_framework(this.tba_framework_identifier).then(()=>{
					// make sure limiters.item_types includes only available item types
					let arr = []
					for (let t of this.tba_available_item_types) {
						if (this.limiters.item_types.includes(t)) arr.push(t)
					}
					this.limiters.item_types = arr
				})
			}
		}},
	},
	methods: {
		report_ms(str) {
			if (!this.debug) return

			let ts = new Date().getTime()
			if (window.last_ts) str += `  [${((ts - window.last_ts) / 1000).toFixed(4)}]`
			console.log(str)
			window.last_ts = ts
		},

		tba_item_types_updated() {
			this.$store.commit('lst_set_hash', ['align_limiters', this.tba_framework_identifier, this.limiters])
		},

		set_tba_framework(framework_identifier) {
			this.tba_framework_identifier = framework_identifier
			U.add_to_recent_frameworks(framework_identifier)
			this.currently_aligning = true
		},

		clear_tba_framework() {
			this.tba_framework_identifier = ''
			this.currently_aligning = false
		},

		load_framework(framework_identifier, flag) {
			return new Promise((resolve, reject)=>{
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==framework_identifier)
				// U.loading_start()
				this.$store.dispatch('get_lsdoc', framework_identifier).then(()=>{
					// from crosswalks, we have to process each of the CFAssociations
					if (flag == 'crosswalk') for (let i = 0; i < fr.json.CFAssociations.length; ++i) {
						fr.json.CFAssociations[i] = new CFAssociation(fr.json.CFAssociations[i])
					}

					// then build the cfo for the framework
					U.build_cfo(this.$worker, fr.json).then((cfo)=>{
						this.$store.commit('set', [fr, 'framework_json_loading', false])
						this.$store.commit('set', [fr, 'cfo', cfo])
						resolve()
						// U.loading_stop()
					}).catch((e)=>{
						// U.loading_stop()
						console.warn(e)
						reject()
					})

				}).catch((e)=>{
					console.warn(e)
					// U.loading_stop()
					this.$alert('An error occurred when loading the competency framework.')
					reject()
				})
			})
		},

		comp_factors_changed(param, val) {
			// console.log('comp_factors_changed', this.comp_factors)
			this.$store.commit('lst_set', ['align_comp_factors', JSON.stringify(this.comp_factors)])
		},

		// get_resource_vectors

		get_resource_alignment_suggestions(resource, framework_identifier) {
			this.report_ms('gras start: ' + resource.resource_title)
			return new Promise((resolve, reject)=>{
				let params = {
					resource: resource,
					framework_identifier: framework_identifier,
					framework_record: this.$store.state.framework_records.find(x=>x.lsdoc_identifier==framework_identifier),
					crosswalks: {},		// framework records for crosswalks, indexed by framework_identifier
					cw_frs: {},		// framework records for frameworks we're considering crosswalks for, indexed by framework_identifier
					ai_simscores: {},
					alignment_crosswalk_data: [],
					education_level_candidate_found: false,
					candidates: [],
					resolve: resolve,
					reject: reject,
				}

				if (!this.comp_factors.item && !this.comp_factors.parent) {
					this.gras2(params)
					return
				}

				// construct text for SB Vectors
				let comp_strings = [], has_comp_strings = false
				if (resource.resource_title) {
					comp_strings[0] = U.normalize_string_for_sparkl_bot(resource.resource_title)
					has_comp_strings = true
				}
				if (resource.description) {
					comp_strings[1] = U.normalize_string_for_sparkl_bot(resource.description)
					has_comp_strings = true
				}
				if (resource.text) {
					comp_strings[2] = U.normalize_string_for_sparkl_bot(resource.text)
					has_comp_strings = true
				}

				if (!has_comp_strings) {
					this.$store.commit('set', [params.resource, 'comp_strings', ''])
					this.$store.commit('set', [params.resource, 'comp_string_vectors', []])

				} else {
					let css = comp_strings.join('XXX')
					if (css != params.resource.comp_strings) {
						// some of the comp_strings might be empty; we can't send those
						let strings = []
						for (let s of comp_strings) if (s) strings.push(s)
						// get SB Vectors
						let payload = {
							service_url: 'sparkl_bot_vectors',
							strings: JSON.stringify(strings)
						}
						this.$store.dispatch('service', payload).then(result => {
							// console.log('vectors', result.vectors)
							if (result.vectors) {
								let vectors = []
								for (let s of comp_strings) vectors.push(s ? result.vectors.shift() : null)
								this.$store.commit('set', [params.resource, 'comp_strings', css])
								this.$store.commit('set', [params.resource, 'comp_string_vectors', vectors])
								this.gras2(params)
							} else {
								console.error('get_resource_alignment_suggestions 1')
								params.reject()
							}

						}).catch(result=>{
							console.error('get_resource_alignment_suggestions 2', result)
							params.reject()
						})
						return
					}
				}

				// if we get to here we don't need to get vectors, so proceed
				this.gras2(params)
			})
		},

		gras2(params) {
			// this.report_ms('  gras2')
			// if the framework isn't full load it, do so
			if (!params.framework_record.framework_json_loaded && !params.framework_record.framework_json_loading) {
				this.load_framework(params.framework_identifier).then(()=>{
					this.gras3(params)
				}).catch(result=>{
					console.error('gras2', result)
					params.reject()
				})
			} else {
				this.gras3(params)
			}
		},

		gras3(params) {
			// this.report_ms('  gras3')
			// if we don't already have the vectors for the framework_identifier, get them
			if (!params.framework_record.sparkl_bot_vectors) {
				this.$store.dispatch('get_framework_sparkl_bot_vectors', params.framework_identifier).then(x=>{
					this.gras4(params)
				}).catch(result=>{
					console.error('gras3', result)
					params.reject()
				})
			} else {
				this.gras4(params)
			}
		},

		gras4(params) {
			// this.report_ms('  gras3 complete')
			// if we're using current alignments and this resource has alignments to other frameworks, get crosswalks and/or vectors between the to-be-aligned-to framework and those other frameworks
			params.alignment_crosswalk_data = []
			if (this.comp_factors.existing_alignments && params.resource.alignments.length > 0) {
				for (let alignment of params.resource.alignments) {
					// if the alignment is to the one we're currently aligning to, skip it
					if (alignment.framework_identifier == params.framework_identifier) continue

					// if base_item_for_alignment is set and the alignment isn't to that item, skip it
					if (!empty(this.base_item_for_alignment) && alignment.item_identifier != this.base_item_for_alignment.identifier) {
						continue
					}

					// if we get to here consider the alignment
					let cfr = params.crosswalks[alignment.framework_identifier]
					// if we haven't already identified this crosswalk for a previous alignment...
					if (!cfr) {
						// look for this crosswalk; if found...
						cfr = U.get_crosswalk_framework_record(alignment.framework_identifier, params.framework_identifier)
						if (cfr) {
							// if crosswalk isn't loaded, load it, then re-call gras4 when it finishes
							if (!cfr.framework_json_loaded || cfr.framework_json_loading) {
								if (!cfr.framework_json_loading) this.load_framework(cfr.lsdoc_identifier, 'crosswalk').then(x=>{
									// console.warn('recalling gras4')
									this.gras4(params)
								})
								return
							}
						} else {
							// this alignment is to a framework that doesn't have a crosswalk to the framework we're currently aligning to
							continue
						}
						// if we get to here, the crosswalk is loaded; store it in params
						params.crosswalks[alignment.framework_identifier] = cfr
					}

					// if we don't already have this framework and its vectors, get both
					let cw_fr = this.framework_records.find(x=>x.lsdoc_identifier==alignment.framework_identifier)
					if (!cw_fr.framework_json_loaded && !cw_fr.framework_json_loading) {
						// console.warn('loading crosswalk framework: ' + cw_fr.json.CFDocument.title)
						this.load_framework(alignment.framework_identifier).then(()=>{
							this.gras4(params)
						}).catch(result=>{
							console.error('gras4', result)
							params.reject()
						})
						return
					}
					// if we get to here, the crosswalk framework is loaded; store it in params
					params.cw_frs[alignment.framework_identifier] = cw_fr

					if (!cw_fr.sparkl_bot_vectors) {
						this.$store.dispatch('get_framework_sparkl_bot_vectors', cw_fr.lsdoc_identifier).then(x=>{
							// console.warn('loading framework vectors: ' + cw_fr.json.CFDocument.title)
							this.gras4(params)
						}).catch(result=>{
							console.error('gras4', result)
							params.reject()
						})
						return
					} else if (cw_fr.sparkl_bot_vectors == 'loading') {
						// vectors are already loading, so return
						return
					}
				
					// get the crosswalk data for params
					let o = {
						framework_identifier: alignment.framework_identifier, 
						item_identifier: alignment.item_identifier,
						educationLevel: cw_fr.cfo.cfitems[alignment.item_identifier].educationLevel
					}

					// look for sme associations
					if (this.comp_factors.sme_assocs) {
						o.sme_assocs = cfr.cfo.associations_hash[alignment.item_identifier]
					}
					// we will calculate ai associations on the fly below

					params.alignment_crosswalk_data.push(o)
				}
			}

			// console.warn('calling gras5')
			// if we get to here we have the crosswalks we need (or we don't need any crosswalks), so move on
			this.report_ms('  gras4 complete')
			this.gras5(params)
		},

		gras5(params) {
			this.gras6_count = 0
			// recursively process either the whole framework's tree, or the designated ancestor
			// let top_node = this.limiters.lowest_ancestor || 
			this.simscore_calc_count = 0
			if (this.limiters.include_branches.length == 0) {
				++this.gras6_count
				this.gras6(params.framework_record.cfo.cftree, params, 0)
			} else {
				for (let identifier of this.limiters.include_branches) {
					++this.gras6_count
					let node = params.framework_record.cfo.cfitems[identifier].tree_nodes[0]
					this.gras6(node, params, 0)
				}
			}
			this.report_ms('  gras5-6 complete; count: ' + this.gras6_count)
			this.gras7(params)
		},

		gras6(node, params, level) {
			let candidate = null
			// don't process this node if it's not one of the alignable item_types
			if (this.limiters.item_types.includes(U.item_type_string(node.cfitem))) {
				candidate = new Resource_Alignment_Candidate({
					framework_identifier: params.framework_identifier,
					cfitem: node.cfitem,
				})

				// if using education, and this resource has educationLevel specified...
				if (this.comp_factors.education_level && params.resource.educationLevel?.length > 0) {
					// resource has educationLevel(s)...
					if (node.cfitem.educationLevel?.length > 0) {
						candidate.comps.education_level = U.educationLevel_distance(params.resource.educationLevel, node.cfitem.educationLevel)
					}

					// if node didn't have an educationLevel (in which case education_level will be -1), or if the diff is > ed_level_tolerance, don't process this candidate
					if (candidate.comps.education_level == -1 || candidate.comps.education_level > this.ed_level_tolerance) {
						// console.log('skipping candidate because education_level = ' + candidate.comps.education_level)
						candidate = null
					}
				}
			}

			if (candidate) {
				// get this node's item's SB vector
				let item_vector = params.framework_record.sparkl_bot_vectors[node.cfitem.identifier]

				// compare to item if we have comp_strings (which will be the case if comp_factors.title, .description, or .text is true and we have at least one of these values for the resource)
				if (params.resource.comp_strings) {
					// for now at least, use the highest simscore calculated from title, description, or text
					// (we could shift to use the average...)
					let simscore = -1
					// use the highest simscore...
					if (false) {
						if (this.comp_factors.title && params.resource.resource_title) {
							let ss = U.cosine_similarity(item_vector, params.resource.comp_string_vectors[0])
							if (ss > simscore) simscore = ss
						}
						if (this.comp_factors.description && params.resource.description) {
							let ss = U.cosine_similarity(item_vector, params.resource.comp_string_vectors[1])
							if (ss > simscore) simscore = ss
						}
						if (this.comp_factors.text && params.resource.text) {
							let ss = U.cosine_similarity(item_vector, params.resource.comp_string_vectors[2])
							if (ss > simscore) simscore = ss
						}
						if (simscore > -1) candidate.comps.item = (simscore < 0) ? 0 : Math.round(simscore * 1000)
					
					// or use the average simscores
					} else {
						let n = 0
						if (this.comp_factors.title) {
							++n
							if (params.resource.resource_title) {
								if (simscore == -1) simscore = 0
								if (item_vector && params.resource.comp_string_vectors[0]) simscore += U.cosine_similarity(item_vector, params.resource.comp_string_vectors[0])
							}
						}
						if (this.comp_factors.description) {
							++n
							if (params.resource.description) {
								if (simscore == -1) simscore = 0
								if (item_vector && params.resource.comp_string_vectors[1]) simscore += U.cosine_similarity(item_vector, params.resource.comp_string_vectors[1])
							}
						}
						if (this.comp_factors.text) {
							++n
							if (params.resource.text) {
								if (simscore == -1) simscore = 0
								if (item_vector && params.resource.comp_string_vectors[2]) simscore += U.cosine_similarity(item_vector, params.resource.comp_string_vectors[2])
							}
						}
						if (n > 0) candidate.comps.item = (simscore < 0) ? 0 : Math.round((simscore / n) * 1000)
					}
				}

				// compare to parent (if this node has a parent)
				if (params.resource.comp_strings && this.comp_factors.parent && node.parent_node?.cfitem.fullStatement) {
					let parent_item_vector = params.framework_record.sparkl_bot_vectors[node.parent_node.cfitem.identifier]
					let simscore = -1
					if (this.comp_factors.title && params.resource.resource_title) {
						let ss = U.cosine_similarity(parent_item_vector, params.resource.comp_string_vectors[0])
						if (ss > simscore) simscore = ss
					}
					if (this.comp_factors.description && params.resource.description) {
						let ss = U.cosine_similarity(parent_item_vector, params.resource.comp_string_vectors[1])
						if (ss > simscore) simscore = ss
					}
					if (this.comp_factors.text && params.resource.text) {
						let ss = U.cosine_similarity(parent_item_vector, params.resource.comp_string_vectors[2])
						if (ss > simscore) simscore = ss
					}
					if (simscore > -1) candidate.comps.parent = (simscore < 0) ? 0 : Math.round(simscore * 1000)
				}

				// if we're considering existing alignments...
				if (this.comp_factors.existing_alignments) {
					// for now at least, use the highest simscore calculated from any existing cross-alignment, for sme and ai alignments separately
					// (we could shift to use the average...)
					let sme_simscore = -1, ai_simscore = -1

					// go through every existing alignment that isn't with the currently-aligning-to framework
					for (let acd of params.alignment_crosswalk_data) {
						// look for sme associations
						if (this.comp_factors.sme_assocs && acd.sme_assocs) {
							for (let assoc of acd.sme_assocs) {
								if (assoc.destinationNodeURI.identifier == node.cfitem.identifier || assoc.originNodeURI.identifier == node.cfitem.identifier) {
									// console.warn('found assoc: ' + assoc.associationType)
									let ss
									// TODO: make these values configurable?
									if (assoc.associationType == 'exactMatchOf') ss = 1000
									else if (assoc.associationType == 'ext:isNearExactMatch') ss = 950
									else if (assoc.associationType == 'ext:isCloselyRelatedTo') ss = 850
									else if (assoc.associationType == 'ext:isModeratelyRelatedTo') ss = 700
									else if (assoc.associationType == 'isRelatedTo') ss = 700
									else ss = 350
									if (ss > sme_simscore) sme_simscore = ss
								}
							}
						}

						// calculate the ai association if we're using them
						if (this.comp_factors.ai_assocs) {
							let ss = -1
							// If we've already calculated this ai_assoc, grab it
							let key = `${node.cfitem.identifier}=${acd.item_identifier}`
							if (!empty(this.calculated_ai_simscores[key])) {
								// console.log('using pre-calculated simscore')
								ss = this.calculated_ai_simscores[key]
							} else {
								// get the SB vector for the associated item
								let cw_item_vector = params.cw_frs[acd.framework_identifier]?.sparkl_bot_vectors[acd.item_identifier]
								if (empty(cw_item_vector)) console.warn('couldn’t get cw_item_vector')
								// store the cosine_similarity in this.calculated_ai_simscores to avoid re-calculating later if we come across the same cross-alignment for another resource
								else {
									// console.log('calculating simscore')
									ss = this.calculated_ai_simscores[key] = U.cosine_similarity(item_vector, cw_item_vector) * 1000
									// if items both have ed levels, scale ss according to ed_level_diff
									if (acd.educationLevel?.length > 0 && node.cfitem.educationLevel?.length > 0) {
										let eld = U.educationLevel_distance(acd.educationLevel, node.cfitem.educationLevel)
										// this is the same algorithm we use for scaling overall simscores by educationLevel below
										if (eld > 0) {
											let ed_factor = 1/`1.${eld}`
											// console.log(`${Math.round(ss)} / ${eld}: ${ed_factor} / ${Math.pow(ss, ed_factor)}`)
											ss = Math.pow(ss, 1/`1.${eld}`)
										}
									}
									++this.simscore_calc_count
								}
							}
							if (ss > ai_simscore) ai_simscore = ss
						}

					}

					if (sme_simscore > -1) candidate.comps.sme_assocs = Math.round(sme_simscore)
					if (ai_simscore > -1) candidate.comps.ai_assocs = Math.round(ai_simscore)
				}

				params.candidates.push(candidate)
			}

			// regardless of whether or not we processed this node, process the node's children
			for (let child of node.children) {
				++this.gras6_count
				this.gras6(child, params, level + 1)
			}
		},

		gras7(params) {
			// simscore_calc_count: ' + this.simscore_calc_count
			// process and sort candidates
			let arr = []
			for (let candidate of params.candidates) {
				let fs = ''
				// note if the candidate is currently aligned
				candidate.currently_aligned = (params.resource.alignments.findIndex(x=>x.item_identifier == candidate.cfitem.identifier) > -1)

				let num = 0, den = 0
				
				// evaluate each factor that is in effect
				if (this.comp_factors.item) {
					let numf = candidate.comps.item * this.comp_factor_weights.item
					num += numf
					den += this.comp_factor_weights.item
					fs += `<div class="k-resource-factor-tooltip"><nobr class="k-resource-factor-description">Item:</nobr><v-spacer></v-spacer><nobr>${candidate.comps.item} &times; ${this.scaled_comp_factor_weights.item}% =</nobr><nobr class="k-resource-factor-total">${Math.round(numf/this.comp_factor_total_weight)}</nobr></div>`
				}
				if (this.comp_factors.parent) {
					let numf = candidate.comps.parent * this.comp_factor_weights.parent
					num += numf
					den += this.comp_factor_weights.parent
					fs += `<div class="k-resource-factor-tooltip"><nobr class="k-resource-factor-description">Parent:</nobr><v-spacer></v-spacer><nobr>${candidate.comps.parent} &times; ${this.scaled_comp_factor_weights.parent}% =</nobr><nobr class="k-resource-factor-total">${Math.round(numf/this.comp_factor_total_weight)}</nobr></div>`
				}
				if (this.comp_factors.sme_assocs) {
					let numf = candidate.comps.sme_assocs * this.comp_factor_weights.existing_alignment_sme_assocs
					num += numf
					den += this.comp_factor_weights.existing_alignment_sme_assocs
					fs += `<div class="k-resource-factor-tooltip"><nobr class="k-resource-factor-description">SME Assocs:</nobr><v-spacer></v-spacer><nobr>${candidate.comps.sme_assocs} &times; ${this.scaled_comp_factor_weights.existing_alignment_sme_assocs}% =</nobr><nobr class="k-resource-factor-total">${Math.round(numf/this.comp_factor_total_weight)}</nobr></div>`
				}
				if (this.comp_factors.ai_assocs) {
					let numf = candidate.comps.ai_assocs * this.comp_factor_weights.existing_alignment_ai_assocs
					num += numf
					den += this.comp_factor_weights.existing_alignment_ai_assocs
					fs += `<div class="k-resource-factor-tooltip"><nobr class="k-resource-factor-description">AI Assocs:</nobr><v-spacer></v-spacer><nobr>${candidate.comps.ai_assocs} &times; ${this.scaled_comp_factor_weights.existing_alignment_ai_assocs}% =</nobr><nobr class="k-resource-factor-total">${Math.round(numf/this.comp_factor_total_weight)}</nobr></div>`
				}

				// if we don't have any factors to consider and it's not already aligned, it's not a candidate!
				if (den == 0 && !candidate.currently_aligned) {
					continue
				}

				let val = (den == 0) ? 0 : num / den

				// now scale by education_level mismatch if using
				if (this.comp_factors.education_level) {
					// candidate.comps.education_level > 0
					// for a value of 600, we reduce to 335 for a mismatch of 1, 207 for a mismatch of 2, or 137 for a mismatch of 3
					let ed_factor = (candidate.comps.education_level == -1) ? 1 : (1/`1.${candidate.comps.education_level}`).toFixed(2)
					val = Math.pow(val, ed_factor)
					fs += `<div class="k-resource-factor-tooltip mt-1"><nobr class="k-resource-factor-description">Ed. Level Correction:</nobr><nobr class="k-resource-factor-total">${(ed_factor==1)?'none':ed_factor}</nobr></div>`
					// fs += `${fs?'<br>':''}Ed. Level: ${ed_factor})`
				}

				candidate.simscore = Math.round(val)
				candidate.simscore_pct = candidate.simscore / 10
				candidate.factor_string = fs

				arr.push(candidate)
			}

			arr.sort((a,b)=>b.simscore - a.simscore)

			// store at most max_in_memory candidates -- unless resource_alignment_validation is on, in which case we don't want to limit this
			if (this.resource_alignment_validation != true) arr.splice(this.candidates_to_show_params.max_in_memory, 10000000)

			// store candidates, along with string representation of comp_factors and limiters, in resource
			this.$store.commit('set', [params.resource, 'candidates', arr])
			this.$store.commit('set', [params.resource, 'candidate_params', this.calculate_candidate_params(params.resource)])

			// determine number of candidates to show by default
			this.$store.commit('set', [params.resource, 'candidates_showing', 0])
			this.set_candidates_showing(params.resource, 0)

			// flash indicator that a suggestion has been updated
			if (!this.suggestions_updated_showing) {
				this.suggestions_updated_showing = true
				setTimeout(x=>{this.suggestions_updated_showing = false}, 1500)
			}

			params.resolve()
			this.report_ms('  gras7 complete')
		},

		calculate_candidate_params(resource) {
			return this.tba_framework_identifier
				 + [resource.resource_title,resource.description,resource.text].join('XXX')
				 + this.suggestion_factors_string
		},

		// this returns true if we need to calculate new suggestions (candidates) for the resource, based on
		// a) the currently-tba framework
		// b) the resource's title/description/text, and 
		// c) the currently-selected suggestion factors and limiters
		resource_suggestion_dirty(resource) {
			// if we haven't stored resource.candidate_params, we surely need to generate new candidates
			if (empty(resource.candidate_params)) return true

			// otherwise check the saved candidate_params against what the resource's current candidate_params would be
			let s = this.calculate_candidate_params(resource)
			return (resource.candidate_params != s)
		},

		// algorithm for determining how many candidates to show
		set_candidates_showing(resource, n_to_add) {
			if (resource.candidates.length == 0) {
				this.$store.commit('set', [resource, 'candidates_showing', 0])
				return
			}

			// set target_min, based on current value or min_n, plus n_to_add
			let target_min = resource.candidates_showing || this.candidates_to_show_params.min_n
			target_min += n_to_add

			// target_max is the same as target_min if n_to_add is > 0
			let target_max
			if (n_to_add > 0) target_max = target_min
			else target_max = this.candidates_to_show_params.max_n// otherwise use this

			// now go through each candidate...
			let candidates_showing = 0, max_target_simscore = 0, straggler_threshold = 8
			let debug = ''
			for (let candidate of resource.candidates) {
				if (candidates_showing >= target_max) {
					// if we've reached target_max, break UNLESS this candidate's simscore is very close to max_target_simscore
					if ((max_target_simscore - candidate.simscore) <= straggler_threshold) {
						++candidates_showing
						debug += ` - C(${(max_target_simscore - candidate.simscore)} <= ${straggler_threshold})`
						// and reduce straggler_threshold each time we do this
						if (straggler_threshold > 1) straggler_threshold /= 2

						continue
					}
					debug += ` - D(${(max_target_simscore - candidate.simscore)} > ${straggler_threshold})`
					break
				}

				if (candidates_showing < target_min) { 
					++candidates_showing
					debug += ' - A'
				} else if (candidate.simscore > this.candidates_to_show_params.threshold) {
					++candidates_showing
					debug += ' - B'
				}
				
				max_target_simscore = candidate.simscore
			}
			this.$store.commit('set', [resource, 'candidates_showing', candidates_showing])
			// console.warn('set_candidates_showing: ' + debug)
		},

		make_alignments(resource, aligned_items) {
			return new Promise((resolve, reject)=>{
				// prepare resource data in same form used to import resources; note that we don't have to send any info except resource_id, and note alignments form
				let rdata = {
					resource_id: resource.resource_id,
					alignments: [],
				}

				for (let aligned_item of aligned_items) {
					rdata.alignments.push({
						fi: aligned_item.framework_identifier,
						ii: aligned_item.cfitem.identifier
					})
				}

				let payload = {
					service_url: 'save_resources',
					resources: JSON.stringify([rdata]),
					user_id: this.user_info.user_id,
					return_resources: 'no',
					return_resource_alignments: 'no',
					return_raw_alignments: 'yes',
				}
				if (aligned_items.length < 1) U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					if (aligned_items.length < 1) U.loading_stop()

					// add alignments to the resource, based on new_alignments we get back from the service
					for (let new_alignment of result.new_alignments) {
						this.$store.commit('set', [resource.alignments, 'PUSH', new Resource_Alignment(new_alignment)])

						// also if the resource has candidates and this aligned item is one of them, mark it as currently aligned
						let candidate = resource.candidates.find(x=>x.cfitem.identifier == new_alignment.item_identifier)
						if (candidate) this.$store.commit('set', [candidate, 'currently_aligned', true])
					}

					resolve()

				}).catch((result)=>{
					U.loading_stop()
					this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
					reject()
				}).finally(()=>{})
			})
		},

		clear_alignments(resource, items_to_clear) {
			return new Promise((resolve, reject)=>{
				// prepare resource data to clear alignment
				let rdata = {
					resource_id: resource.resource_id,
					alignments: []
				}

				// find resource_alignment_ids to clear
				for (let item of items_to_clear) {
					let a = resource.alignments.find(x=>x.framework_identifier == item.framework_identifier && x.item_identifier == item.cfitem.identifier)
					if (a) rdata.alignments.push({clear: a.resource_alignment_id})
				}
				if (rdata.alignments.length == 0) return	// shouldn't happen

				let payload = {
					service_url: 'save_resources',
					resources: JSON.stringify([rdata]),
					user_id: this.user_info.user_id,
					return_resources: 'no',
					return_resource_alignments: 'no',
					return_raw_alignments: 'no',
				}
				if (rdata.alignments.length < 1) U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					if (rdata.alignments.length < 1) U.loading_stop()

					// remove alignments from the resource
					for (let o of rdata.alignments) {
						let i = resource.alignments.findIndex(x=>x.resource_alignment_id == o.clear)
						let alignment = resource.alignments[i]
						if (i > -1) this.$store.commit('set', [resource.alignments, 'SPLICE', i])

						// also if the resource has candidates and this item is one of them, mark it as not currently aligned
						let candidate = resource.candidates.find(x=>x.cfitem.identifier == alignment?.item_identifier)
						if (candidate) this.$store.commit('set', [candidate, 'currently_aligned', false])
					}

					resolve()

				}).catch((result)=>{
					U.loading_stop()
					this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
					reject()
				}).finally(()=>{})
			})
		},

	}
}