<template><div>
  <p><strong>Export CASE JSON</strong></p>
	<p>The Export <span v-html="link('case_json', 'CASE JSON')"></span> can be used to export the framework data in the standardized JSON format.</p>
	<ul>
		<li>Select the <span v-html="link('kebab_menu', 'kebab menu')"></span> within a framework</li>
		<li>Select EXPORT FRAMEWORK / COPY FRAMEWORK LINK</li>
		<li>Select EXPORT CASE JSON
			<ul>
				<li>The <strong>CASE 1.0</strong> version of the framework’s JSON, will include only fields that are part of the official CASE 1.0 specification.</li>
				<li>Alternatively, the <strong>CASE 1.1</strong> version includes additional item fields that are part of the official CASE 1.1 specification.</li>
				<li>Choosing the <strong>Extended CASE</strong> version includes additional fields that are included in <span v-html="app_name"/> but are not part of the official CASE specification (e.g. Supplemental Information fields for items).</li>
			</ul>
		</li>
		<li>Click the <strong>SELECT FORMAT</strong> button to download the JSON.</li>
	</ul>
     
     </div></template>
     <script>
         import HelpMixin from './HelpMixin'
         export default {
             mixins: [HelpMixin],
         }
     </script>
     
     <style lang="scss">
     </style>